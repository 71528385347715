 

import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row } from 'bootstrap-4-react';
import { Parallax } from "react-parallax";
import image1 from './../../assets/AboutAssets/SP_About_Assets-01.jpg';
class ContactBanner extends PureComponent {

  render() {
    return (
      <Fragment>

        <Parallax className="bannerSec contactBanner" bgImage={image1} strength={200}>
          <Container fluid className="banner-container">
            <Row>
              <Col md={7}>
                <div className="banner-head">
                  <h2 className="felisSemiBold" data-aos='fade-up'>The smart solution and
obvious choice for your outdoor spaces</h2>
                </div>
              </Col>
            </Row>
          </Container>
        </Parallax>
      </Fragment>
    )
  }
}


export default ContactBanner