import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row } from 'bootstrap-4-react';
import Image1 from '../../assets/SP_Home_Assets-04.jpg';
import Image2 from '../../assets/SP_Home_Assets-05.jpg';
import { Parallax, Background } from "react-parallax";
const container = {
    hidden: {
        opacity: 0,
    },
    show: {
        opacity: 1,

        transition: {
            delayChildren: 2,
            staggerChildren: 0.3,
        }
    }

}

const item = {
    hidden: {
        opacity: 0,
    },
    show: {
        opacity: 1,
    }

}
class HomeOurStory extends PureComponent {

    render() {
        return (
            <Fragment>


                <section className="sp-our-story-sec">

                    <Container fluid className="wrap sp-our-story-container">

                        <Row className="sp-our-story-head-row" data-scroll data-scroll-delay="0.13" data-scroll-speed='3'>
                            <Col md={10}>
                                <div className="sp-our-story-div">
                                    <div className="sp-our-story-headiv" data-aos='fade-up'><h3> Our</h3><span className="lineclass"></span><h3> Story</h3></div>
                                    <div className="felisSemiBold text-div story-head" data-aos='fade-up'>Sudarsan Planx’s unparalleled product
                                        features and uncompromising quality
                                        has led to the creation of planks that
                                        are virtually maintenance free!</div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="sp-our-story-image-row">
                            <Col md={4}>
                                <div className="left-img ">
                                    <Parallax bgImage={Image1} strength={300} className="d-md-block">
                                    </Parallax>
                                    <img src={Image1} className="img-fluid d-md-none" alt="prod-img" />
                                </div>

                            </Col>
                            <Col md={8}>
                                <div className="right-img">
                                    <Parallax bgImage={Image2} strength={300} className="d-md-block">
                                    </Parallax>
                                    <img src={Image2} className="img-fluid d-md-none" alt="prod-img" />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

            </Fragment>
        )
    }
}


export default HomeOurStory