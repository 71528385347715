import React, { PureComponent, Fragment } from 'react';

import Header from '../../common/header';
import Footer from '../../common/footer'; 
import ExteriorBanner from '../exterior-component/exterior-banner';
import ExteriorExterior from '../exterior-component/exterior-exterior';
import ExteriorSlider1 from '../exterior-component/exterior-slider1';
// import ExteriorSlider2 from '../exterior-component/exterior-slider2';
import ExteriorOurProjects from '../exterior-component/exterior-our-projects';
import LetsTalk from '../exterior-component/lets-talk';
import {Helmet} from "react-helmet";

class ExteriorPage extends PureComponent {
    render() {
        return (

            <Fragment>
                
                <Helmet> 
                    <title>Sudarsan Planx - Our Products - Planx XL, Planx Deck, Planx Flutex, Planx Tile & Planx Fin </title>
                    <meta name="description" content="Sudarsan Planx has retained their unique designs to give you a discernible aesthetic with a contemporary style. All our products are manufactured with only one goal in mind – to be durable."></meta>
                    <link rel="canonical" href="https://www.sudarsanplanx.com/exterior" />
                </Helmet> 

                <Header />

                <ExteriorBanner />

                <ExteriorExterior />


                 <ExteriorSlider1 />

                 {/* <ExteriorSlider2 /> */}

                 <ExteriorOurProjects />

                 <LetsTalk />

                <Footer />

            </Fragment>
        )

    }
}

export default ExteriorPage