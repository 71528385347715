import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row } from 'bootstrap-4-react';


class ApplicationMain extends PureComponent {

    render() {
        return (
            <Fragment>


                <section className="application-desc">

                    <Container className="application-container"> 

                        <Row className="application-row">
                            <Col md={12}>
                                <div className="application-desc-content">
                                    <h4 className="felisBold" data-aos='fade-up'>Application</h4>
                                    <p data-aos='fade-left'>At Sudarsan Planx we have the ability to curate and employ WPC planxs for a wide range of applications. They surpass the use of
conventional timbers as they don’t rot, warp and crack and are eco-friendly and more practical.</p>
<p data-aos='fade-left'>They are easy to install and can be installed on existing concrete posts or any other reinforcements to improve the look and feel
of luxury homes! Since they are less vulnerable to abrasions and are highly durable, our planxs can be used for gates, fencing,
duct
covering, elevation designing, wall/ceiling panelling, gazebos, etc.</p>
                                </div>
                            </Col>
                        </Row>

                    </Container>
                </section>
            </Fragment>
        )
    }
}


export default ApplicationMain