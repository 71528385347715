import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row, Button } from 'bootstrap-4-react'; 
import { Link } from "react-router-dom";
import blogImage1 from '../../assets/BlogMainPage/SP_Blogs_assets-02.jpg';
import blogImage2 from '../../assets/BlogMainPage/SP_Blogs_assets-03.jpg';
import blogImage3 from '../../assets/BlogMainPage/SP_Blogs_assets-04.jpg';
import blogImage4 from '../../assets/BlogMainPage/SP_Blogs_assets-05.jpg';
import blogImage5 from '../../assets/BlogMainPage/SP_Blogs_assets-06.jpg';
import blogImage6 from '../../assets/BlogMainPage/SP_Blogs_assets-07.jpg';
import { Parallax } from "react-parallax";
class LatestBlogs extends PureComponent {

  render() {
    return (
      <Fragment>


        <section className="latest-blogs-sec">
          <Container fluid>
              <Row className="latest-blogs-head">
                  <Col md={6} className="latest-blogs-left-col">
                      <div className="common-heading"  data-aos='fade-right'>
                          <h3 className="felisBold"> Latest Insights </h3>   
                      </div>
                  </Col>
                  <Col md={6}  className="latest-blogs-right-col">
                    <div className="location-search-form"  data-aos='fade-left'>
                        <form action="#">
                            <label for="searchTextField" className="sr-only">SEARCH KEYWORD</label>
                            <input type="text" id="searchTextField"
                                placeholder="SEARCH KEYWORD" autocomplete="email" />
                            <button type="submit" className="search-btn search-btn-yellow next">Submit</button>
                        </form>
                    </div>
                  </Col>
                  <div className="year-border-bottom"  data-aos='fade-up'>
                      <p>2022</p>
                  </div>
              </Row>  
          </Container>
          <Container fluid className="latest-blogs-container"> 
              <Row className="latest-blogs-row">
                <Col md={6} className="latest-blogs-col"> 
                    <div className="blogs-card"  data-aos='fade-right'>
                        <Link to="/wpc">
                          <div className="blogs-img">
                              {/* <img src={blogImage1} className="img-fluid d-md-none" /> */}
                              <Parallax bgImage={blogImage1} strength={-400} className="d-md-block">
                                    </Parallax>
                          </div>
                          <div className="blogs-content">
                            <h4>WPC Vs. Natural Wood - Which Is Better?</h4>
                            <p>WPC planxs are wood-plastic composites that are composed of materials like wood fibre and thermoplastics.</p>
                            <p className="blog-date">14 JUL ‘22</p>
                          </div>
                        </Link>
                    </div> 
                </Col>
                <Col md={6} className="latest-blogs-col">
                    <div className="blogs-card"  data-aos='fade-left'>
                        <Link to="/why-choose-sp">
                          <div className="blogs-img">
                              {/* <img src={blogImage2} className="img-fluid d-md-none" /> */}
                              <Parallax bgImage={blogImage2} strength={-400} className="d-md-block">
                                    </Parallax>
                          </div>
                          <div className="blogs-content">
                            <h4>Why Choose Sudarsan Planxs?</h4>
                            <p>The look and feel of Sudarsan Planx is unrivalled to any other type of planxs in the market.</p>
                            <p className="blog-date">14 JUL ‘22</p>
                          </div>
                        </Link>
                    </div> 
                </Col> 
              </Row>
              <Row className="latest-blogs-row">
                <Col md={6} className="latest-blogs-col"> 
                    <div className="blogs-card"  data-aos='fade-right'>
                        <Link to="/process-of-laying">
                          <div className="blogs-img">
                              {/* <img src={blogImage3} className="img-fluid d-md-none" /> */}
                              <Parallax bgImage={blogImage3} strength={-400} className="d-md-block">
                                    </Parallax>
                          </div>
                          <div className="blogs-content">
                            <h4>Process Of Laying A Deck Using The Planxs.</h4>
                            <p>WPC is the perfect choice for pool decking because it is waterproof.</p>
                            <p className="blog-date">14 JUL ‘22</p>
                          </div>
                        </Link>
                    </div> 
                </Col>
                {/* <Col md={6} className="latest-blogs-col">
                    <div className="blogs-card"  data-aos='fade-left'>
                        <a href="blog-inner">
                          <div className="blogs-img">
                              <img src={blogImage4} className="img-fluid" />
                          </div>
                          <div className="blogs-content">
                            <h4>Lorem ipsum dolor sit amet .</h4>
                            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
commodo ligula eget dolor. Aenean massa.</p>
                            <p className="blog-date">14 JUL ‘22</p>
                          </div>
                        </a>
                    </div> 
                </Col>  */}
              </Row>

              {/* <Row className="latest-blogs-row">
                <Col md={6} className="latest-blogs-col"> 
                    <div className="blogs-card" data-aos='fade-right'>
                        <a href="blog-inner">
                          <div className="blogs-img">
                              <img src={blogImage5} className="img-fluid" />
                          </div>
                          <div className="blogs-content">
                            <h4>Lorem ipsum dolor sit amet .</h4>
                            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
commodo ligula eget dolor. Aenean massa.</p>
                            <p className="blog-date">14 JUL ‘22</p>
                          </div>
                        </a>
                    </div> 
                </Col>
                <Col md={6} className="latest-blogs-col">
                    <div className="blogs-card" data-aos='fade-left'>
                        <a href="blog-inner">
                          <div className="blogs-img">
                              <img src={blogImage6} className="img-fluid" />
                          </div>
                          <div className="blogs-content">
                            <h4>Lorem ipsum dolor sit amet .</h4>
                            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
commodo ligula eget dolor. Aenean massa.</p>
                            <p className="blog-date">14 JUL ‘22</p>
                          </div>
                        </a>
                    </div> 
                </Col> 
              </Row> */}
              {/* <Row className="common-flex-center">
                  <Col md={6}>
                      <div className=" "> 
                        <a href="blog-inner"><Button className="spButton">Load More</Button></a>
                      </div>
                  </Col>
              </Row>  */}
          </Container>
        </section>
      </Fragment>
    )
  }
}


export default LatestBlogs