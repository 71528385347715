import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row } from 'bootstrap-4-react';
import Image1 from '../../../assets/BlogInnerPage/second-blog-1st-image.jpg';
import Image2 from '../../../assets/BlogInnerPage/second-blog-2nd-image.jpg';
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
class WhychooseMain extends PureComponent {

    render() {
        return (
            <Fragment>
                <section className="blog-inner-description-sec">
                    <Container fluid className="blog-inner-description-container">
                        <Row className="blog-inner-description-row">
                            <Col md={8}>
                                <div className="blog-inner-desc-text">
                                    <p data-aos='fade-right'>The look and feel of Sudarsan Planx is unrivalled to any other type of planks in the market. It’s unique features offer the best quality and offers more than just robust protection to your surfaces; it augments its style as well.  </p>
                                </div>

                            </Col>
                        </Row>
                        <Row className="blog-inner-description-row">
                            <Col md={12}>
                                <div className="blog-inner-desc-img">
                                    <div className="blogs-img" data-aos='fade-left'>
                                        <img src={Image1} className="img-fluid" alt="blog-inner-img" />
                                    </div>
                                </div>

                            </Col>
                        </Row>
                        <Row className="blog-inner-description-row">
                            <Col md={8}>
                                <div className="blog-inner-desc-text">


                                    <p data-aos='fade-right'>Our WPC planks are made from  a combination of the best of natural wood, plant husk and fused with recycled polymer composite in an effort to help decrease the environmental pollution. When you choose Sudarsan Planx, you are choosing the best wooden polymer composite planks that money can buy. So, you get the best of natural aesthetics with increased utility and functionality that comes from the polymer composite.</p>

                                    <p data-aos='fade-right'>Our range of premium, exterior planks are prudently designed keeping in mind the various needs of our customers. Choose from a range of different profiles, colours and sizes to maximize the look and minimize product wastage.</p>

                                    <p data-aos='fade-right'>Why choose our planks?</p>

                                    <h5>Low maintenance:</h5>
                                    <p data-aos='fade-right'>Rest assured with our planks that ensure gorgeous, outdoor spaces that does not compromise on epic quality, strength and durability. Our Planx offer you a stellar look with an even more stellar performance. They also provide you with a sleek finish and maximized benefits. </p>

                                    <h5 data-aos='fade-right'>Weather/ UV resistant:</h5>
                                    <p data-aos='fade-right'>Highly weather and UV resistant, Planx offer you the best of the best protection from water leakage, rot and decay. They are a highly beneficial, eco-friendly alternative for normal wooden planks. </p>

                                    <h5 data-aos='fade-right'>Anti-corrosive:</h5>
                                    <p data-aos='fade-right'>We believe in giving you the best product with absolutely no compromise on quality.  corrosion-free, termite-treated and highly durable.. Designed to last long and provide a stellar use, Sudarsan Planx is a must-have in your homes to add some style without much hassle. </p>

                                    <h5 data-aos='fade-right'>Eco-friendly:</h5>
                                    <p data-aos='fade-right'>We believe in providing the best quality planks with minimum product wastage so that customers can get the best out of it. The eco-friendly planks that we offer will make for a designing solution that will make you happy, and the environment happy.</p>

                                    <h5 data-aos='fade-right'>Extended Warranty</h5>
                                    <p data-aos='fade-right'>Enjoy your premium WPC planks for a long time with our extended warranty that will allow you to reap the benefits of aesthetics and functionality. Our guaranteed warranty period lasts up to 10 years of benefits.</p>

                                    <h5 data-aos='fade-right'>Aesthetics:</h5>
                                    <p data-aos='fade-right'>We manufacture our Planx with one goal in mind - to remodel and renovate your home into a complete show stopper. Which ever you pick, we assure you one thing - our natural timber looking planks will add to the aesthetic of your home. </p>

                                    <h5 data-aos='fade-right'>DIY-ability:</h5>
                                    <p data-aos='fade-right'>Sudarsan Planx provides your homes with an aesthetic to dream of, yet spares you the hassle of installation. Our Planx comes with an extremely easy DIY installation method that requires no manpower or hefty electrical equipment.  </p>
                                </div>

                            </Col>
                        </Row>
                        <Row className="blog-inner-description-row">
                            <Col md={12}>
                                <div className="blog-inner-desc-img">
                                    <div className="blogs-img" data-aos='fade-left'>
                                        <img src={Image2} className="img-fluid" alt="blog-inner-imgs" />
                                    </div>
                                </div>

                            </Col>
                        </Row>
                        <Row className="blog-inner-description-row">
                            <Col md={8}>
                                <div className="blog-inner-desc-border-bottoom">

                                </div>

                            </Col>
                        </Row>
                        <Row className="blog-inner-description-row">
                            <Col md={8}>
                                <div className="blog-inner-social-share">
                                    <div class="flex-social-div">
                                        <p>Share</p>
                                        <a href="#"><FaFacebookF /></a>
                                        <a href="#"><FaTwitter /></a>
                                        <a href="#"><FaLinkedinIn /></a>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </Container>


                </section>
            </Fragment>
        )
    }
}


export default WhychooseMain



