import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row } from 'bootstrap-4-react';
import Table from 'react-bootstrap/Table';
// import Swiper core and required modules
import { Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import Image1 from '../../assets/ExteriorAssets/SUDARSAN PLANX INNER Asset-15.png';
import Image2 from '../../assets/ExteriorAssets/SUDARSAN PLANX INNER Asset-09.jpg';
import Image3 from '../../assets/ExteriorAssets/SUDARSAN PLANX INNER Asset-16.png';


import productLargeimg1 from '../../assets/new-imgs/product-large-img1.jpg';
import productLargeimg2 from '../../assets/new-imgs/product-large-img2.jpg'; 
import productLargeimg3 from '../../assets/new-imgs/product-large-img3.jpg'; 
import productLargeimg4 from '../../assets/new-imgs/product-large-img4.jpg'; 
import productLargeimg5 from '../../assets/new-imgs/product-large-img5.jpg'; 
import productLargeimg6 from '../../assets/new-imgs/product-large-img6.jpg';


import productSmallimg1 from '../../assets/new-imgs/product-small-img1.jpg';
import productSmallimg2 from '../../assets/new-imgs/product-small-img2.jpg'; 
import productSmallimg3 from '../../assets/new-imgs/product-small-img3.jpg'; 
import productSmallimg4 from '../../assets/new-imgs/product-small-img4.jpg'; 
import productSmallimg5 from '../../assets/new-imgs/product-small-img5.jpg'; 
import productSmallimg6 from '../../assets/new-imgs/product-small-img6.jpg';


class InteriorSlider1 extends PureComponent {

    render() {
        return (
            <Fragment>
 <section className="product-details-swiper">
 <Container fluid className="product-details-container">
                        <Row className="product-details-row">
                            <Col md={6} className="padding-zero swiper-products-div" data-aos='fade-right'>
                                <div className="products-images-swiper-div">
                                    <Swiper className="products-images-swiper" modules={[Pagination]}
                                        spaceBetween={10} slidesPerView={1} grabCursor={true} centeredSlides={true} pagination={{ clickable: true }}
                                        loop={true}>
                                        <SwiperSlide className="products-images-slide">
                                            <div className="products-image-div">
                                                <img src={productLargeimg1} className="img-fluid" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide className="products-images-slide">
                                            <div className="products-image-div">
                                                <img src={Image3} className="img-fluid" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide className="products-images-slide">
                                            <div className="products-image-div">
                                                <img src={Image1} className="img-fluid" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide className="products-images-slide">
                                            <div className="products-image-div">
                                                <img src={Image3} className="img-fluid" />
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            </Col>
                            <Col md={6} className="padding-zero product-details-div" data-aos='fade-left'>
                                <div className="product-detail-div">

                                    <div className="product-details-content">
                                        <Row>

                                            <Col md={6} className="text-left">
                                                <div className="product-details-color">

                                                    <div className="color-names">
                                                        <p>Color</p>
                                                        <div className="color_palatte  d-flex justify-content-center ">
                                                            <div className="color_palatte-box">
                                                                <div className="color_wrapper d-flex justify-content-center align-items-center rounded-circle">
                                                                    <div className="rounded-circle latte"> </div>
                                                                </div>
                                                            </div>
                                                            <div className="color_palatte-box">
                                                                <div className="color_wrapper d-flex justify-content-center align-items-center rounded-circle">
                                                                    <div className="rounded-circle coffee"> </div>
                                                                </div>
                                                            </div>
                                                            <div className="color_palatte-box">
                                                                <div className="color_wrapper d-flex justify-content-center align-items-center rounded-circle">
                                                                    <div className="rounded-circle choco"> </div>
                                                                </div>
                                                            </div>
                                                            <div className="color_palatte-box">
                                                                <div className="color_wrapper d-flex justify-content-center align-items-center rounded-circle">
                                                                    <div className="rounded-circle teak"> </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={6} className="text-right">
                                                <div className="product-details-name">
                                                    <h6 className="felisBold">Plan XL</h6>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="product-details-desc">
                                        <Row>
                                            <Col md={9}>
                                                <div className="product-details-desc-div">
                                                    <h6>Design Pattern</h6>
                                                    <p>The Planx XL is a robust and great-looking outdoor product. Solid planks enhance durability and share more resemblance to real wood.</p>
                                                    <p>Planx XL possesses a unique natural timber look on one side, to give an extraordinary aesthetic to the building and has a symmetrical grooved look on the other, which promotes uniformity. Though it is best suited to be used on gates, exterior wall, louvers, duct covering, elevations and partitions it can be used on other applications as well like fencing and exterior ceiling.</p>
                                                    <p>It is used for elevations, duct covering, Gates, exterior ceiling, fencing etc.</p>
                                                </div>
                                            </Col>
                                            <Col md={3}>
                                                <div className="product-details-desc-img">
                                                    <img src={productSmallimg1} className="img-fluid" />
                                                    <p>Grooved</p>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <div className="product-details-desc-div">
                                                    <p>₹ 150 (Price per RFT) (INCLUSIVE OF GST)</p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="product-detail-table">
                                        <Table responsive striped bordered hover variant="light">
                                            <thead>
                                                <tr>
                                                    <th>Length</th>
                                                    <th>Width</th>
                                                    <th>Thickness</th>
                                                    <th>Planx/Box</th>
                                                    <th>Price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>5FT</td>
                                                    <td>145 mm</td>
                                                    <td>10 mm</td>
                                                    <td>6 planx</td>
                                                    <td> ₹ 750</td>
                                                </tr>
                                                <tr>
                                                    <td>8FT</td>
                                                    <td>145 mm</td>
                                                    <td>10 mm</td>
                                                    <td>6 planx</td>
                                                    <td> ₹ 1200</td>
                                                </tr>
                                                <tr>
                                                    <td>9.5FT</td>
                                                    <td>145 mm</td>
                                                    <td>10 mm</td>
                                                    <td>6 planx</td>
                                                    <td> ₹ 1475</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>

                                </div>

                            </Col>
                        </Row>
                    </Container>
                    <Container fluid className="product-details-container">
                        <Row className="product-details-row">
                            <Col md={6} className="padding-zero product-details-div" data-aos='fade-right'>
                                <div className="product-detail-div">

                                    <div className="product-details-content">
                                        <Row>
                                            <Col md={6} className="text-left">
                                                <div className="product-details-name">
                                                    <h6 className="felisBold">PLANX Deck</h6>
                                                </div>
                                            </Col>
                                            <Col md={6} className="text-right">
                                                <div className="product-details-color">

                                                    <div className="color-names">
                                                        <p>Color</p>
                                                        <div className="color_palatte  d-flex justify-content-center ">
                                                            <div className="color_palatte-box">
                                                                <div className="color_wrapper d-flex justify-content-center align-items-center rounded-circle">
                                                                    <div className="rounded-circle latte"> </div>
                                                                </div>
                                                            </div>
                                                            <div className="color_palatte-box">
                                                                <div className="color_wrapper d-flex justify-content-center align-items-center rounded-circle">
                                                                    <div className="rounded-circle coffee"> </div>
                                                                </div>
                                                            </div>
                                                            <div className="color_palatte-box">
                                                                <div className="color_wrapper d-flex justify-content-center align-items-center rounded-circle">
                                                                    <div className="rounded-circle choco"> </div>
                                                                </div>
                                                            </div> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>

                                        </Row>
                                    </div>
                                    <div className="product-details-desc">
                                        <Row>
                                            <Col md={9}>
                                                <div className="product-details-desc-div">
                                                    <h6>Design Pattern</h6>
                                                    <p>Designed keeping in mind of its primary use i.e. Swimming pool decking and exterior deck flooring where the water needs to drain out. The wood within also remains dry and intact for longevity. </p>
                                                    <p> It has grooves that also ensures a better space for footing in wet areas.</p>
                                                </div>
                                            </Col>
                                            <Col md={3}>
                                                <div className="product-details-desc-img">
                                                    <img src={productSmallimg2} className="img-fluid" />
                                                    <p>Grained</p>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <div className="product-details-desc-div">
                                                    <p>₹ 200 (Price per RFT) (INCLUSIVE OF GST)</p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="product-detail-table">
                                        <Table responsive striped bordered hover variant="light">
                                            <thead>
                                                <tr>
                                                    <th>Length</th>
                                                    <th>Width</th>
                                                    <th>Thickness</th>
                                                    <th>Planx/Box</th>
                                                    <th>Price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td> 9.5FT</td>
                                                    <td>146 mm</td>
                                                    <td>21 mm</td>
                                                    <td>6 planx</td>
                                                    <td>₹ 1900.00</td>
                                                </tr> 
                                            </tbody>
                                        </Table>
                                    </div>

                                </div>

                            </Col>
                            <Col md={6} className="padding-zero swiper-products-div" data-aos='fade-left'>
                                <div className="products-images-swiper-div">
                                    <Swiper className="products-images-swiper" modules={[Pagination]}
                                        spaceBetween={10} slidesPerView={1} grabCursor={true} centeredSlides={true} pagination={{ clickable: true }}
                                        loop={true}>
                                        <SwiperSlide className="products-images-slide">
                                            <div className="products-image-div">
                                                <img src={productLargeimg2} className="img-fluid" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide className="products-images-slide">
                                            <div className="products-image-div">
                                                <img src={Image3} className="img-fluid" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide className="products-images-slide">
                                            <div className="products-image-div">
                                                <img src={Image1} className="img-fluid" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide className="products-images-slide">
                                            <div className="products-image-div">
                                                <img src={Image3} className="img-fluid" />
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            </Col>

                        </Row>
                    </Container>
                    <Container fluid className="product-details-container">
                        <Row className="product-details-row">
                            <Col md={6} className="padding-zero swiper-products-div" data-aos='fade-right'>
                                <div className="products-images-swiper-div">
                                    <Swiper className="products-images-swiper" modules={[Pagination]}
                                        spaceBetween={10} slidesPerView={1} grabCursor={true} centeredSlides={true} pagination={{ clickable: true }}
                                        loop={true}>
                                        <SwiperSlide className="products-images-slide">
                                            <div className="products-image-div">
                                                <img src={productLargeimg3} className="img-fluid" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide className="products-images-slide">
                                            <div className="products-image-div">
                                                <img src={Image3} className="img-fluid" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide className="products-images-slide">
                                            <div className="products-image-div">
                                                <img src={Image1} className="img-fluid" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide className="products-images-slide">
                                            <div className="products-image-div">
                                                <img src={Image3} className="img-fluid" />
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            </Col>
                            <Col md={6} className="padding-zero product-details-div" data-aos='fade-left'>
                                <div className="product-detail-div">

                                    <div className="product-details-content">
                                        <Row>

                                            <Col md={6} className="text-left">
                                                <div className="product-details-color">

                                                    <div className="color-names">
                                                        <p>Color</p>
                                                        <div className="color_palatte  d-flex justify-content-center ">
                                                            <div className="color_palatte-box">
                                                                <div className="color_wrapper d-flex justify-content-center align-items-center rounded-circle">
                                                                    <div className="rounded-circle latte"> </div>
                                                                </div>
                                                            </div>
                                                            <div className="color_palatte-box">
                                                                <div className="color_wrapper d-flex justify-content-center align-items-center rounded-circle">
                                                                    <div className="rounded-circle coffee"> </div>
                                                                </div>
                                                            </div>
                                                            <div className="color_palatte-box">
                                                                <div className="color_wrapper d-flex justify-content-center align-items-center rounded-circle">
                                                                    <div className="rounded-circle choco"> </div>
                                                                </div>
                                                            </div> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={6} className="text-right">
                                                <div className="product-details-name">
                                                    <h6 className="felisBold">PLANX FLUTEX</h6>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="product-details-desc">
                                        <Row>
                                            <Col md={9}>
                                                <div className="product-details-desc-div">
                                                    <h6>Design Pattern</h6>
                                                    <p>The Plank Flutex possesses an exceptional aesthetic aside from being strikingly symmetrical. This is best used in exterior walls of homes to further enhance the overall appearance. They are also perfectly suited for gates as they are incredibly durable and versatile.</p> 
                                                </div>
                                            </Col>
                                            <Col md={3}>
                                                <div className="product-details-desc-img">
                                                    <img src={productSmallimg3} className="img-fluid" />
                                                    <p>Grooved</p>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <div className="product-details-desc-div">
                                                    <p>₹ 221 (Price per RFT) (INCLUSIVE OF GST)</p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="product-detail-table">
                                        <Table responsive striped bordered hover variant="light">
                                            <thead>
                                                <tr>
                                                    <th>Length</th>
                                                    <th>Width</th>
                                                    <th>Thickness</th>
                                                    <th>Planx/Box</th>
                                                    <th>Price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td> 9.5FT</td>
                                                    <td>100 mm</td>
                                                    <td>50 mm</td>
                                                    <td>6 planx</td>
                                                    <td>₹ 2100.00</td>
                                                </tr> 
                                            </tbody>
                                        </Table>
                                    </div>

                                </div>

                            </Col>
                        </Row>
                    </Container>
                    <Container fluid className="product-details-container">
                        <Row className="product-details-row">
                            <Col md={6} className="padding-zero product-details-div" data-aos='fade-right'>
                                <div className="product-detail-div">

                                    <div className="product-details-content">
                                        <Row>
                                            <Col md={6} className="text-left">
                                                <div className="product-details-name">
                                                    <h6 className="felisBold">PLANX FLUTEIN</h6>
                                                </div>
                                            </Col>
                                            <Col md={6} className="text-right">
                                                <div className="product-details-color">

                                                    <div className="color-names">
                                                        <p>Color</p>
                                                        <div className="color_palatte  d-flex justify-content-center ">
                                                            <div className="color_palatte-box">
                                                                <div className="color_wrapper d-flex justify-content-center align-items-center rounded-circle">
                                                                    <div className="rounded-circle latte"> </div>
                                                                </div>
                                                            </div>
                                                            <div className="color_palatte-box">
                                                                <div className="color_wrapper d-flex justify-content-center align-items-center rounded-circle">
                                                                    <div className="rounded-circle coffee"> </div>
                                                                </div>
                                                            </div> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>

                                        </Row>
                                    </div>
                                    <div className="product-details-desc">
                                        <Row>
                                            <Col md={9}>
                                                <div className="product-details-desc-div">
                                                    <h6>Design Pattern</h6>
                                                    <p>Planx flutein has a laminated finish making it your first and best choice for interior wall paneling and indoor decorations. Made with PVC, the material is light yet durable with widely spaced grooves not to mention incredibly aesthetic.</p>
                                                </div>
                                            </Col>
                                            <Col md={3}>
                                                <div className="product-details-desc-img">
                                                    <img src={productSmallimg4} className="img-fluid" />
                                                    <p>Grained</p>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <div className="product-details-desc-div">
                                                    <p>₹ 285 (Price per RFT) (INCLUSIVE OF GST)</p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="product-detail-table">
                                        <Table responsive striped bordered hover variant="light">
                                            <thead>
                                                <tr>
                                                    <th>Length</th>
                                                    <th>Width</th>
                                                    <th>Thickness</th>
                                                    <th>Planx/Box</th>
                                                    <th>Price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td> 9.5FT</td>
                                                    <td>196 mm</td>
                                                    <td>25 mm</td>
                                                    <td>6 planx</td>
                                                    <td>₹ 2708.00</td>
                                                </tr> 
                                            </tbody>
                                        </Table>
                                    </div>

                                </div>

                            </Col>
                            <Col md={6} className="padding-zero swiper-products-div" data-aos='fade-left'>
                                <div className="products-images-swiper-div">
                                    <Swiper className="products-images-swiper" modules={[Pagination]}
                                        spaceBetween={10} slidesPerView={1} grabCursor={true} centeredSlides={true} pagination={{ clickable: true }}
                                        loop={true}>
                                        <SwiperSlide className="products-images-slide">
                                            <div className="products-image-div">
                                                <img src={productLargeimg4} className="img-fluid" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide className="products-images-slide">
                                            <div className="products-image-div">
                                                <img src={Image3} className="img-fluid" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide className="products-images-slide">
                                            <div className="products-image-div">
                                                <img src={Image1} className="img-fluid" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide className="products-images-slide">
                                            <div className="products-image-div">
                                                <img src={Image3} className="img-fluid" />
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            </Col>

                        </Row>
                    </Container>
                    <Container fluid className="product-details-container">
                        <Row className="product-details-row">
                            <Col md={6} className="padding-zero swiper-products-div" data-aos='fade-right'>
                                <div className="products-images-swiper-div">
                                    <Swiper className="products-images-swiper" modules={[Pagination]}
                                        spaceBetween={10} slidesPerView={1} grabCursor={true} centeredSlides={true} pagination={{ clickable: true }}
                                        loop={true}>
                                        <SwiperSlide className="products-images-slide">
                                            <div className="products-image-div">
                                                <img src={productLargeimg5} className="img-fluid" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide className="products-images-slide">
                                            <div className="products-image-div">
                                                <img src={Image3} className="img-fluid" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide className="products-images-slide">
                                            <div className="products-image-div">
                                                <img src={Image1} className="img-fluid" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide className="products-images-slide">
                                            <div className="products-image-div">
                                                <img src={Image3} className="img-fluid" />
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            </Col>
                            <Col md={6} className="padding-zero product-details-div" data-aos='fade-left'>
                                <div className="product-detail-div">

                                    <div className="product-details-content">
                                        <Row>

                                            <Col md={6} className="text-left">
                                                <div className="product-details-color">

                                                    <div className="color-names">
                                                        <p>Color</p>
                                                        <div className="color_palatte  d-flex justify-content-center ">
                                                            <div className="color_palatte-box">
                                                                <div className="color_wrapper d-flex justify-content-center align-items-center rounded-circle">
                                                                    <div className="rounded-circle beechwood"> </div>
                                                                </div>
                                                            </div> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={6} className="text-right">
                                                <div className="product-details-name">
                                                    <h6 className="felisBold">PLANX Tile</h6>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="product-details-desc">
                                        <Row>
                                            <Col md={9}>
                                                <div className="product-details-desc-div">
                                                    <h6>Design Pattern</h6>
                                                    <p>The Planx Tile as the name suggests is used for tiling. They possess a water-repelling effect on the exterior that ensures the wood remains dry within while also providing a great footing when used as decking around the swimming pool and flooring of the bathrooms. </p>
                                                    <p>They are also used in the balcony setting as they are aesthetically appealing and durable.</p>
                                                </div>
                                            </Col>
                                            <Col md={3}>
                                                <div className="product-details-desc-img">
                                                    <img src={productSmallimg5} className="img-fluid" />
                                                    <p>Grooved</p>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <div className="product-details-desc-div">
                                                    <p>₹ 127 (Price per RFT) (INCLUSIVE OF GST)</p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="product-detail-table">
                                        <Table responsive striped bordered hover variant="light">
                                            <thead>
                                                <tr>
                                                    <th>Length</th>
                                                    <th>Width</th>
                                                    <th>Thickness</th>
                                                    <th>Planx/Box</th>
                                                    <th>Price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td> 9.5FT</td>
                                                    <td>170 mm</td>
                                                    <td>20 mm</td>
                                                    <td>6 planx</td>
                                                    <td>₹ 1207.00</td>
                                                </tr> 
                                            </tbody>
                                        </Table>
                                    </div>

                                </div>

                            </Col>
                        </Row>
                    </Container>
                    <Container fluid className="product-details-container">
                        <Row className="product-details-row">
                            <Col md={6} className="padding-zero product-details-div" data-aos='fade-right'>
                                <div className="product-detail-div">

                                    <div className="product-details-content">
                                        <Row>
                                            <Col md={6} className="text-left">
                                                <div className="product-details-name">
                                                    <h6 className="felisBold">PLANX FIN</h6>
                                                </div>
                                            </Col>
                                            <Col md={6} className="text-right">
                                                <div className="product-details-color">

                                                    <div className="color-names">
                                                        <p>Color</p>
                                                        <div className="color_palatte  d-flex justify-content-center ">
                                                            <div className="color_palatte-box">
                                                                <div className="color_wrapper d-flex justify-content-center align-items-center rounded-circle">
                                                                    <div className="rounded-circle coffee"> </div>
                                                                </div>
                                                            </div>
                                                            <div className="color_palatte-box">
                                                                <div className="color_wrapper d-flex justify-content-center align-items-center rounded-circle">
                                                                    <div className="rounded-circle walnut"> </div>
                                                                </div>
                                                            </div> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>

                                        </Row>
                                    </div>
                                    <div className="product-details-desc">
                                        <Row>
                                            <Col md={9}>
                                                <div className="product-details-desc-div">
                                                    <h6>Design Pattern</h6>
                                                    <p>If you’re looking to enhance the aesthetic of your rooftop, the Planx Fin are your go-to product. They are used to make mostly the pergolas which provide viable balance between shade and light in the rooftops making it an excellent spot to spend leisure time. Being extremely versatile, it can be used outdoors under almost all weather conditions. They are also used for elevation purposes.</p>
                                                </div>
                                            </Col>
                                            <Col md={3}>
                                                <div className="product-details-desc-img">
                                                    <img src={productSmallimg6} className="img-fluid" />
                                                    <p>Grained</p>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <div className="product-details-desc-div">
                                                    <p>₹ 475 (Price Per tile) (INCLUSIVE OF GST)</p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="product-detail-table">
                                        <Table responsive striped bordered hover variant="light">
                                            <thead>
                                                <tr>
                                                    <th>Length</th>
                                                    <th>Width</th>
                                                    <th>Thickness</th>
                                                    <th>Planx/Box</th>
                                                    <th>Price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td> 9.5FT</td>
                                                    <td>300 mm</td>
                                                    <td>300 mm</td>
                                                    <td>6 planx</td>
                                                    <td>₹ 475.00</td>
                                                </tr> 
                                            </tbody>
                                        </Table>
                                    </div>

                                </div>

                            </Col>
                            <Col md={6} className="padding-zero swiper-products-div" data-aos='fade-left'>
                                <div className="products-images-swiper-div">
                                    <Swiper className="products-images-swiper" modules={[Pagination]}
                                        spaceBetween={10} slidesPerView={1} grabCursor={true} centeredSlides={true} pagination={{ clickable: true }}
                                        loop={true}>
                                        <SwiperSlide className="products-images-slide">
                                            <div className="products-image-div">
                                                <img src={productLargeimg6} className="img-fluid" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide className="products-images-slide">
                                            <div className="products-image-div">
                                                <img src={Image3} className="img-fluid" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide className="products-images-slide">
                                            <div className="products-image-div">
                                                <img src={Image1} className="img-fluid" />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide className="products-images-slide">
                                            <div className="products-image-div">
                                                <img src={Image3} className="img-fluid" />
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            </Col>

                        </Row>
                    </Container>
                </section>

 
            </Fragment>
        )
    }
}


export default InteriorSlider1