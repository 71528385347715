import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row } from 'bootstrap-4-react';
import { Link } from 'react-router-dom'; 
import { useLayoutEffect, useRef, useState, useEffect } from "react";
// import Swiper core and required modules
import { Navigation, Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Image1 from './../../assets/new-imgs/fins-fluted-elevation.jpg';
import Image2 from './../../assets/new-imgs/wpc-decking.jpg';
// import Image2 from './../../assets/new-imgs/WPC-Tile-design.jpg';

import Image3 from './../../assets/new-imgs/fins.jpg';
import Image4 from './../../assets/new-imgs/gate.jpg';
import Image5 from './../../assets/new-imgs/fluted-fin-elevation.jpg';
import nextbuttonImg from './../../assets/SP_Home_Assets-19.svg';

import { Parallax, Background } from "react-parallax";

class HomeApplications extends PureComponent {

    render() {
        return (


            <Fragment>
                <section className="sp-home-applications">

                    <Container fluid className="padding-zero">
                        <div className="appHead">
                            <h3 className='felisBold'>Applications</h3> 
                        </div>
                        

                        <Swiper
                            // install Swiper modules
                            modules={[Navigation, Pagination]}
                            spaceBetween={50} 
                            centeredSlides={true} 
                            grabCursor={true}
                            loop={true}
                            slidesPerView={4.5}
                            navigation={true}
                            pagination={{type:"fraction"}} 
                            className={"home-applications-swiper"}
                            breakpoints={{
                                320: {
                                  // width: 576,
                                  slidesPerView: 1,
                                },
                                 768: {
                                  // width: 768,
                                  slidesPerView: 3,
                                }, 
                                1024: {
                                    // width: 768,
                                    slidesPerView: 4.5,
                                  },
                              }}
                        >
                            <SwiperSlide>
                                <div className="app-con-div">
                                    <div className='app-img-div'>
                                        <img src={Image1} className="img-fluid" alt="app-img" />
                                        {/* <Parallax bgImage={Image1} strength={-300} > </Parallax> */}
                                        <div className='app-slider-content-div'>
                                            <h3 className='felisRegular'>Cladding</h3>
                                            <p>
                                            Sudarsan Planx changes the entire façade of your building with its cladding boards. They maintain the look of your building for years as they are extremely resilient to fading.  
                                            </p> 
                                            <Link to="/application" className="learnMoreBtn">Learn More </Link>
                                        </div>
                                    </div>
                                 
                                </div> 
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="app-con-div">
                                    <div className='app-img-div'>
                                        <img src={Image2} className="img-fluid" alt="app-img" />
                                        {/* <Parallax bgImage={Image2} strength={-300} >
                                    </Parallax> */}
                                        <div className='app-slider-content-div'>
                                            <h3 className='felisRegular'>Decking and Tiles</h3>
                                            <p>
                                            We provide planks for decking and tiles in colour options that will compliment the look of your space effortlessly. Our planks are easy to maintain but are high on performance.
                                            </p> 
                                            <Link to="/application" className="learnMoreBtn">Learn More </Link>
                                        </div>
                                    </div>
                                  
                                </div> 
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="app-con-div">
                                    <div className='app-img-div'>
                                        <img src={Image3} className="img-fluid" alt="app-img" />
                                        {/* <Parallax bgImage={Image3} strength={-300} >   </Parallax> */}
                                        <div className='app-slider-content-div'>
                                            <h3 className='felisRegular'>Pergolas and Fins</h3>
                                            <p>
                                            For pergolas and fins, gazebos and elevation, we recommend the Planx Hollow. They are much lighter than actual wood and steel frames and are comparatively much easier to install.
                                            </p> 
                                            <Link to="/application" className="learnMoreBtn">Learn More </Link>
                                        </div>
                                    </div>
                                
                                </div>  

                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="app-con-div">
                                    <div className='app-img-div'>
                                        <img src={Image4} className="img-fluid" alt="app-img" />
                                        {/* <Parallax bgImage={Image4} strength={-300} ></Parallax> */}
                                        <div className='app-slider-content-div'>
                                            <h3 className='felisRegular'>Gates</h3>
                                            <p>
                                            A gate is more than an entryway for a house; it is what welcomes people into your home! Sudarsan Planx’s ultra-light, premium quality composite planks are perfect for your gates. 
                                            </p> 
                                            <Link to="/application" className="learnMoreBtn">Learn More </Link>
                                        </div>
                                    </div>
                                 
                                </div>  
                            </SwiperSlide>
                            <SwiperSlide>
                                <div className="app-con-div">
                                    <div className='app-img-div'>
                                        <img src={Image5} className="img-fluid" alt="app-img" />
                                        {/* <Parallax bgImage={Image5} strength={-300} ></Parallax> */}
                                        <div className='app-slider-content-div'>
                                            <h3 className='felisRegular'>Railings and Louvers</h3>
                                            <p>
                                            Our planks are the best choice for your railings and louvers as the beauty of natural wood is weaved into the durable composite. Thanks to their high resistance to deterioration and low maintenance. 
                                            </p> 
                                            <Link to="/application" className="learnMoreBtn">Learn More </Link>
                                        </div>
                                    </div>
                                 
                                </div>  
                            </SwiperSlide>
                        </Swiper>
                    </Container>

                </section>

            </Fragment>
        );
    }

}

export default HomeApplications;