import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row } from 'bootstrap-4-react';
import { Parallax, Background } from "react-parallax"; 

import image1 from './../../assets/SP_Home_Assets-01.jpg';
const styles = {
  fontFamily: "sans-serif",
  textAlign: "center"
};

const insideStyles = {
  background: "white",
  padding: 20,
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)"
};

class HomeBanner extends PureComponent {


  render() {
    return (
      <Fragment> 
        <Parallax className="bannerSec" bgImage={image1} strength={200} >
          <Container fluid className="banner-container">
            <Row>
              <Col md={6}>
                <div className="banner-head">
                  <h2 className="felisSemiBold" data-scroll data-scroll-delay="0.13" data-scroll-speed='4'>A unique aesthetic twist for your space!</h2>
                </div>
              </Col>
            </Row>
          </Container>
        </Parallax>
      </Fragment>
    )
  }
}


export default HomeBanner

 
