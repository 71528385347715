import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row } from 'bootstrap-4-react';
import { useLayoutEffect, useRef, useState, useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation, Controller } from "swiper";
import Black1Img from '../../assets/features-icons/SP_Home_Assets_1.svg';
import Black2Img from '../../assets/features-icons/SP_Home_Assets_2.svg';
import Black3Img from '../../assets/features-icons/SP_Home_Assets_3.svg';
import Black4Img from '../../assets/features-icons/SP_Home_Assets_4.svg';
import Black5Img from '../../assets/features-icons/SP_Home_Assets_5.svg';
import Black6Img from '../../assets/features-icons/SP_Home_Assets_6.svg';
import Black7Img from '../../assets/features-icons/SP_Home_Assets_7.svg';
import Black8Img from '../../assets/features-icons/SP_Home_Assets_8.svg';
import Black9Img from '../../assets/features-icons/SP_Home_Assets_9.svg';
import Black10Img from '../../assets/features-icons/SP_Home_Assets_10.svg';

import RedImg1 from '../../assets/features-icons/SP_Home_Assets_11.svg';
import RedImg2 from '../../assets/features-icons/SP_Home_Assets_12.svg';
import RedImg3 from '../../assets/features-icons/SP_Home_Assets_13.svg';
import RedImg4 from '../../assets/features-icons/SP_Home_Assets_14.svg';
import RedImg5 from '../../assets/features-icons/SP_Home_Assets_15.svg';
import RedImg6 from '../../assets/features-icons/SP_Home_Assets_16.svg';
import RedImg7 from '../../assets/features-icons/SP_Home_Assets_17.svg';
import RedImg8 from '../../assets/features-icons/SP_Home_Assets_18.svg';
import RedImg9 from '../../assets/features-icons/SP_Home_Assets_19.svg';
import RedImg10 from '../../assets/features-icons/SP_Home_Assets_20.svg';
 
export default function App() {
    const swiper1Ref = useRef();
    const swiper2Ref = useRef();



    useLayoutEffect(() => {
        swiper1Ref.current.controller.control = swiper2Ref.current;
        swiper2Ref.current.controller.control = swiper1Ref.current;



    }, []);



    return (

        <Fragment>
            <section className="sp-unique-features">

                <Container fluid>
                    <Row className="common-flex-center">
                        <Col md={8 } >
                            <div className="common-heading">
                                <h1 className='felisBold'>  Unique Features</h1> 
                                <div className='lineclassx'></div>
                            </div>
                        </Col>
                    </Row> 
                </Container>

                <Container fluid>
                    <Row className="common-flex-center">
                        <Col md={9}>
                            <div className="features-swiper-div">
                            <Swiper
                                onSwiper={(swiper) => {
                                    swiper1Ref.current = swiper;

                                }}
                                slidesPerView={5} 
                                loopedSlides={5}
                                centeredSlides={true} 
                                breakpoints={{ 
                                    320: {
                                        slidesPerView: 1,
                                    },
                                    768: {
                                        slidesPerView: 3,
                                    },
                                    1280: {
                                        slidesPerView: 5,
                                    },
                                    
                                }}

                                activeslidekey={20}
                                // onSlideChange={(e) =>


                                //     console.log(e.realIndex)


                                // }
                                loop={true}  
                                modules={[Pagination, Navigation, Controller]}
                                className="unique-features-images"
                            >
                                <SwiperSlide>

                                    <div id={'slide_1'} className="features-image">
                                        <img src={Black1Img}  className="blackslide img-fluid" />
                                        <img src={RedImg1}  className="redslide img-fluid" />

                                        {/* <div className="seperator"> </div> */}
                                    </div>

                                </SwiperSlide>
                                <SwiperSlide>
                                    <div id={'slide_2'} className="features-image">
                                        <img src={Black2Img}  className="blackslide img-fluid" />
                                        <img src={RedImg2}  className="redslide img-fluid" />

                                        {/* <div className="seperator"> </div> */}
                                    </div>

                                </SwiperSlide>
                                <SwiperSlide>
                                    <div id={'slide_3'} className="features-image">
                                        <img src={Black3Img}  className="blackslide img-fluid" />
                                        <img src={RedImg3}  className="redslide img-fluid" />

                                        {/* <div className="seperator"> </div> */}
                                    </div>


                                </SwiperSlide>

                                <SwiperSlide>
                                    <div id={'slide_4'} className="features-image">
                                        <img src={Black4Img}  className="blackslide img-fluid" />
                                        <img src={RedImg4}  className="redslide img-fluid" />

                                        {/* <div className="seperator">  </div> */}
                                    </div>


                                </SwiperSlide>
                                <SwiperSlide className="slide" key1='20'>
                                    <div id={'slide_5'} className="features-image">
                                        <img src={Black5Img}  className="blackslide img-fluid" />
                                        <img src={RedImg5}  className="redslide img-fluid" />

                                        {/* <div className="seperator"></div> */}
                                    </div>

                                </SwiperSlide>
                                <SwiperSlide>

                                    <div id={'slide_1'} className="features-image">
                                        <img src={Black6Img}  className="blackslide img-fluid" />
                                        <img src={RedImg6}  className="redslide img-fluid" />

                                        {/* <div className="seperator"> </div> */}
                                    </div>

                                    </SwiperSlide>
                                    <SwiperSlide>
                                    <div id={'slide_2'} className="features-image">
                                        <img src={Black7Img}  className="blackslide img-fluid" />
                                        <img src={RedImg7}  className="redslide img-fluid" />

                                        {/* <div className="seperator"> </div> */}
                                    </div>

                                    </SwiperSlide>
                                    <SwiperSlide>
                                    <div id={'slide_3'} className="features-image">
                                        <img src={Black8Img}  className="blackslide img-fluid" />
                                        <img src={RedImg8}  className="redslide img-fluid" />

                                        {/* <div className="seperator"> </div> */}
                                    </div>


                                    </SwiperSlide>

                                    <SwiperSlide>
                                    <div id={'slide_4'} className="features-image">
                                        <img src={Black9Img}  className="blackslide img-fluid" />
                                        <img src={RedImg9}  className="redslide img-fluid" />

                                        {/* <div className="seperator">  </div> */}
                                    </div>


                                    </SwiperSlide>
                                    <SwiperSlide className="slide" key1='20'>
                                    <div id={'slide_5'} className="features-image">
                                        <img src={Black10Img}  className="blackslide img-fluid" />
                                        <img src={RedImg10}  className="redslide img-fluid" />

                                        {/* <div className="seperator"></div> */}
                                    </div>

                                    </SwiperSlide>

                            </Swiper>
                            </div>

                        </Col>
                    </Row>
                </Container>
                
                <Container fluid className="sp-features-container"> 
                <Row>
                    <Col md={12}>
                        <div className="features-swiper-div">
                            <Swiper className='features-swiper' 
                                 onSwiper={(swiper) => {
                                    swiper2Ref.current = swiper;
                                    // console.log(swiper2Ref.current);
                                }}
                                slidesPerView={1}
                                spaceBetween={30}
                                loopedSlides={5}
        
        
                                loop={true} 
                                navigation={true}
                                modules={[Pagination, Navigation, Controller]}
                                  >
                                <SwiperSlide className='features-swiper-slide'>
                                    <div className="swiper-slide-div">
                                        <h3 className='felisRegular'>UV, weather & termite treated</h3>
                                        <p>The planks are protected against the hard UV rays of the sun, can withstand any weather and are impervious to termites.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className='features-swiper-slide'>
                                    <div className="swiper-slide-div">
                                        <h3 className='felisRegular'>Eco-friendly</h3>
                                        <p>These planks are entirely eco-friendly, as they are made of biodegradable materials that are 100% recyclable.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className='features-swiper-slide'>
                                    <div className="swiper-slide-div">
                                        <h3 className='felisRegular'>Natural Wood Aesthetic</h3>
                                        <p>The WPC planks extend the look and feel of natural wood without the additional care and protection needed to preserve wood planks.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className='features-swiper-slide'>
                                    <div className="swiper-slide-div">
                                        <h3 className='felisRegular'>No coating/painting</h3>
                                        <p>Our planks do not require any coating or painting before use, as opposed to wood that needs polishing before usage.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className='features-swiper-slide'>
                                    <div className="swiper-slide-div">
                                        <h3 className='felisRegular'>Anti-corrosive</h3>
                                        <p>Our planks are highly resistant to corrosion, making them excellent for exterior application.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className='features-swiper-slide'>
                                    <div className="swiper-slide-div">
                                        <h3 className='felisRegular'>High performance</h3>
                                        <p> Our planks are low on maintenance but high on performance, with long-lasting life that does not reduce with age and time.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className='features-swiper-slide'>
                                    <div className="swiper-slide-div">
                                        <h3 className='felisRegular'>High strength and non-brittle</h3>
                                        <p> The toughness of the planks ensures that it does not break easily to avoid any brittleness.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className='features-swiper-slide'>
                                    <div className="swiper-slide-div">
                                        <h3 className='felisRegular'>Durable </h3>
                                        <p>Durability is an assurance with our planks, which makes them withstand any weather or outdoor condition like heat, rain, humidity and even salinity.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className='features-swiper-slide'>
                                    <div className="swiper-slide-div">
                                        <h3 className='felisRegular'>Low maintenance </h3>
                                        <p>Our high-performance composite is not prone to warping and rotting and lasts much longer, making them virtually maintenance-free!</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className='features-swiper-slide'>
                                    <div className="swiper-slide-div">
                                        <h3 className='felisRegular'>Easy to install </h3>
                                        <p>The installation process is easy, straightforward and streamlined, done using a simple and efficient procedure.</p>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>

                    </Col>
                </Row>

       
            </Container>


                 
                    {/* <Swiper
                        onSwiper={(swiper) => {
                            swiper2Ref.current = swiper;
                            console.log(swiper2Ref.current);
                        }}
                        slidesPerView={1}
                        spaceBetween={30}
                        loopedSlides={5}


                        loop={true} 
                        navigation={true}
                        modules={[Pagination, Navigation, Controller]}
                        className="mySwiper2"
                    >
                        <SwiperSlide className="slide">
                            <div className='content-div'>
                            <h3 className='FelisSemiBold'>No coating/painting
                                </h3>
                                <p className='FelisSemiBold'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Consectetur voluptatem natus, ratione suscipit animi sit consequuntur perspiciatis repellat dolorem quibusdam enim ducimus magnam aspernatur reiciendis ipsam qui error, blanditiis doloremque?</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="slide">

                            <div className='content-div'>
                            <h3 className='FelisSemiBold'>No coating/painting
                                </h3>
                                <p className='FelisSemiBold'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Consectetur voluptatem natus, ratione suscipit animi sit consequuntur perspiciatis repellat dolorem quibusdam enim ducimus magnam aspernatur reiciendis ipsam qui error, blanditiis doloremque?</p>
                            </div>

                        </SwiperSlide>
                        <SwiperSlide className="slide">

                            <div className='content-div'>
                            <h3 className='FelisSemiBold'>No coating/painting
                                </h3>
                                <p className='FelisSemiBold'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Consectetur voluptatem natus, ratione suscipit animi sit consequuntur perspiciatis repellat dolorem quibusdam enim ducimus magnam aspernatur reiciendis ipsam qui error, blanditiis doloremque?</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="slide">
                            <div className='content-div'>
                            <h3 className='FelisSemiBold'>No coating/painting
                                </h3>
                                <p className='FelisSemiBold'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Consectetur voluptatem natus, ratione suscipit animi sit consequuntur perspiciatis repellat dolorem quibusdam enim ducimus magnam aspernatur reiciendis ipsam qui error, blanditiis doloremque?</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className="slide">

                            <div className='content-div'>
                            <h3 className='FelisSemiBold'>No coating/painting
                                </h3>
                                <p className='FelisSemiBold'>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Consectetur voluptatem natus, ratione suscipit animi sit consequuntur perspiciatis repellat dolorem quibusdam enim ducimus magnam aspernatur reiciendis ipsam qui error, blanditiis doloremque?</p>
                            </div>
                        </SwiperSlide>

                    </Swiper> */}

            </section>

        </Fragment>
    );
}
