import React, { PureComponent, Fragment } from 'react';

import Header from '../../../common/header';
import Footer from '../../../common/footer'; 

import LetsTalk from '../../../components/blog-component/lets-talk';
import WhychooseBanner from './../../blog-component/why-choose-sp/why-choose-sp-banner';
import WhychooseMain from './../../blog-component/why-choose-sp/why-choose-sp-main';
import WhychooseLatestBlogs from './../../blog-component/why-choose-sp/why-choose-sp-latest-blogs';
import {Helmet} from "react-helmet";

class WhychoosePage extends PureComponent {
    render() {
        return (

            <Fragment>

                <Helmet> 
                    <title>Why Choose Sudarsan Planx? | Blog Post</title>
                    <meta name="description" content="Sudarsan Planx is a premium WPC plank manufacturer that creates unconventional and impressive products that are a class apart from other products in the market. Read on to find out more about Sudarsan Planx."></meta>
                    <link rel="canonical" href="https://www.sudarsanplanx.com/why-choose-sp" />
                </Helmet> 
 
                <Header /> 

                <WhychooseBanner />

                <WhychooseMain />

                <WhychooseLatestBlogs />

                <LetsTalk />

                <Footer />

            </Fragment>
        )

    }
}

export default WhychoosePage