import React, { Fragment, PureComponent } from "react";
// Router Links
import { Routes, Route } from "react-router-dom";
import HomePage from './components/home-component/home-page';
import AboutPage from './components/about-component/about-page';
import ContactPage from './components/contact-component/contact-page';
import BlogPage from './components/blog-component/blog-page';
import BlogInnerPage from './components/blog-component/blog-inner/blog-inner-page';
import WpcPage from './components/blog-component/wpc/wpc-page';
import WhychoosePage from './components/blog-component/why-choose-sp/why-choose-sp-page';
import ProcessoflayingPage from './components/blog-component/process-of-laying/process-of-laying-page';
import ApplicationPage from './components/application-component/application-page';
import ExteriorPage from './components/exterior-component/exterior-page';
import InteriorPage from './components/interior-component/interior-page';
import PrivacyPage from './components/privacy-component/privacy-policy-page';
import ScrollToTop from './scrolltotop';   
// AOS ANIMATION
import AOS from 'aos';
import 'aos/dist/aos.css';
// Import styles css
import './styles/styles.scss';
// Import NPM styles
import 'bootstrap-4-react';
import 'react-bootstrap';
import 'react-icons/fa';
// import './styles/style1.scss';

class App extends PureComponent { 
  componentDidMount() {
    AOS.init({
      duration : '1500',   
      once: false,
      mobile:false
    });
    AOS.refresh();
  }
  render (){ 
   

    
    
    return (
    <Fragment >
        <ScrollToTop />  
      <Routes>
        <Route path="/">
          <Route index element={<HomePage />} />
          <Route exact path="home" element={<HomePage />} />
          <Route exact path="about" element={<AboutPage />} />
          <Route exact path="contact" element={<ContactPage />} />
          <Route exact path="blog" element={<BlogPage />} />
          <Route exact path="wpc" element={<WpcPage />} />
          <Route exact path="why-choose-sp" element={<WhychoosePage />} /> 
          <Route exact path="process-of-laying" element={<ProcessoflayingPage />} />
          <Route exact path="blog-inner" element={<BlogInnerPage />} />
          <Route exact path="application" element={<ApplicationPage />} />
          <Route exact path="exterior" element={<ExteriorPage />} />
          <Route exact path="interior" element={<InteriorPage />} />
          <Route exact path="privacy-policy" element={<PrivacyPage />} />

          

          
        </Route>
      </Routes>
    </Fragment> 
      )
    }
}


export default App;
