import React, { PureComponent, Fragment } from 'react';
import { Parallax } from "react-parallax";
import { Container, Col, Row } from 'bootstrap-4-react';
import image1 from './../../assets/BlogMainPage/SP_Blogs_assets-01.jpg';
class BlogBanner extends PureComponent {

  render() {
    return (
      <Fragment>


        <Parallax className="bannerSec blogBanner" bgImage={image1} strength={200}>
          <Container fluid className="banner-container">
            <Row>
              <Col md={6}>
                <div className="banner-head">
                  <h2 className="felisSemiBold" data-scroll data-scroll-delay="0.13" data-scroll-speed='4'>Our Blogs</h2>
                </div>
              </Col>
            </Row>
          </Container>
        </Parallax>
      </Fragment>
    )
  }
}


export default BlogBanner