import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row } from 'bootstrap-4-react';
import Image1 from '../../../assets/BlogInnerPage/first-blog-1st-image.jpg';
import Image2 from '../../../assets/BlogInnerPage/first-blog-2nd-image.jpg';
import blogImage4 from '../../../assets/BlogInnerPage/SP_Blogs_assets-11.jpg';
import blogImage6 from '../../../assets/BlogInnerPage/SP_Blogs_assets-12.jpg';
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
class WpcMain extends PureComponent {

    render() {
        return (
            <Fragment>
                <section className="blog-inner-description-sec">
                    <Container fluid className="blog-inner-description-container">
                        <Row className="blog-inner-description-row">
                            <Col md={8}>
                                <div className="blog-inner-desc-text">
                                    <p data-aos='fade-right'>WPC planks are wood-plastic composites that are composed of materials like wood fibre and thermoplastics (polythene, polypropylene, polyvinyl chloride or polylactic acid). Sometimes WPCs can also contain other cellulosic and/or inorganic filler materials. </p>
                                </div>

                            </Col>
                        </Row>
                        <Row className="blog-inner-description-row">
                            <Col md={12}>
                                <div className="blog-inner-desc-img">
                                    <div className="blogs-img" data-aos='fade-left'>
                                        <img src={Image1} className="img-fluid" alt="blog-inner-img" />
                                    </div>
                                </div>

                            </Col>
                        </Row>
                        <Row className="blog-inner-description-row">
                            <Col md={8}>
                                <div className="blog-inner-desc-text">


                                    <p data-aos='fade-right'>WPC planks are an excellent alternative for natural wood, lasting longer and requiring significantly lesser maintenance. They are also a more environmentally friendly option.</p>

                                    <p data-aos='fade-right'>As of late, WPC planks are used in several construction applications, including decking, cladding, fins, pergolas, gates, etc., in a similar way natural wood is being utilised. But what gives WPC that added advantage when compared to natural wood?</p>

                                    <p data-aos='fade-right'>WPC planks are wood-plastic composites that are composed of materials like wood fibre and thermoplastics (polythene, polypropylene, polyvinyl chloride or polylactic acid). Sometimes WPCs can also contain other cellulosic and/or inorganic filler materials. </p>

                                    <h5>Weather resistant:</h5>
                                    <p data-aos='fade-right'>WPC has the ability to withstand harsher weather conditions when compared to natural wood. WPC planks project the same aesthetic as natural wood while being cheaper, more efficient to maintain and more resistant to water and harsh UV rays. The higher density of WPC ensures that it is sturdier than natural wood and is less prone to split, crack, deteriorate or fade when exposed to harsh climatic conditions.</p>

                                    <h5 data-aos='fade-right'>Durability:</h5>
                                    <p data-aos='fade-right'>WPC planks are more durable than natural wood, meaning they have a longer life and do not require the constant care and maintenance that natural wood needs. The natural sheen of the WPC planks ensures that no external coats of paint or polish are needed to protect the planks. </p>

                                    <h5 data-aos='fade-right'>Maintenance-free:</h5>
                                    <p data-aos='fade-right'>Natural wood is more prone to rot, decay and deterioration due to termites, corrosion, UV rays and direct sunlight. Our WPC planks come pre-treated to combat any of the above, giving them the ability to withstand any adverse conditions they might face. </p>

                                    <h5 data-aos='fade-right'>Cost-effective:</h5>
                                    <p data-aos='fade-right'>Constant upkeep is needed every few years to keep natural wood in its original condition, which is cumbersome and expensive. WPC planks, requiring lesser maintenance, are a cost-effective solution for many looking to obtain the rustic, outdoor look of natural wood without having to worry too much about its expenses. WPC is comparatively cheaper than natural wood while achieving the same outcome as natural wood.</p>

                                    <h5 data-aos='fade-right'>Eco-friendly:</h5>
                                    <p data-aos='fade-right'>WPC planks are also extremely eco-friendly since they are a composite of wood and plastic, as opposed to natural wood, which is obtained by deforestation, thus upsetting the natural balance of the environment.</p>

                                    <h5 data-aos='fade-right'>Inference:</h5>
                                    <p data-aos='fade-right'>All in all, WPC planks are indeed a safer choice when compared to natural wood - cost-effective, eco-friendly anti-corrosive, termite-free, weather-resistant and waterproof. It achieves the same aesthetic as natural wood while being easier to maintain.</p>
                                </div>

                            </Col>
                        </Row>
                        <Row className="blog-inner-description-row">
                            <Col md={12}>
                                <div className="blog-inner-desc-img">
                                    <div className="blogs-img" data-aos='fade-left'>
                                        <img src={Image2} className="img-fluid" alt="blog-inner-imgs" />
                                    </div>
                                </div>

                            </Col>
                        </Row>
                        <Row className="blog-inner-description-row">
                            <Col md={8}>
                                <div className="blog-inner-desc-border-bottoom">

                                </div>

                            </Col>
                        </Row>
                        <Row className="blog-inner-description-row">
                            <Col md={8}>
                                <div className="blog-inner-social-share">
                                    <div class="flex-social-div">
                                        <p>Share</p>
                                        <a href="#"><FaFacebookF /></a>
                                        <a href="#"><FaTwitter /></a>
                                        <a href="#"><FaLinkedinIn /></a>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </Container>


                </section>
            </Fragment>
        )
    }
}


export default WpcMain



