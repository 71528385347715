import React, { PureComponent, Fragment } from 'react';

import { Link, NavLink } from 'react-router-dom';

import { Container, Col, Row, Button } from 'bootstrap-4-react';
import LogoImg from '../assets/SP_Home_Assets-02.svg';
import { Navbar, Nav } from 'react-bootstrap';


class Footer extends PureComponent {

    render() {
        return (

            <Fragment>

                <section className="sp-footer-sec">
                    <Container fluid className="wrap footer-list-container"  data-aos='fade-up'>
                        <Row>
                            <Col md={12}>
                                <div className="footer-div">
                                    <div className="big-text">
                                        <h4 className="felisRegular">Where next?</h4>
                                    </div>
                                    <div className="footer-list-div">
                                        <div className="navbar-sp">
                                            <Navbar> 
                                                <Nav> 
                                                    <Nav.Link as={NavLink} to='/'><span>Home</span></Nav.Link>
                                                    <Nav.Link as={NavLink} to='/about'><span>About</span></Nav.Link>
                                                    <Nav.Link as={NavLink} to='/exterior'><span>Products</span></Nav.Link>
                                                    <Nav.Link as={NavLink} to='/application'><span>Applications</span></Nav.Link>
                                                    <Nav.Link as={NavLink} to='/blog'><span>Blogs</span></Nav.Link>
                                                    <Nav.Link as={NavLink} to='/contact'><span>Contact</span></Nav.Link> 
                                                </Nav>
                                            </Navbar>
                                        </div> 
                                    </div> 
                                </div> 
                            </Col>
                        </Row>
                    </Container>
                    <Container fluid className="wrap copy-right-container">
                        <Row className="copy-right-row">
                            {/* <Col md={3}  data-aos='fade-right'>
                                <div>
                                    <h5 className='flotaleft'> © SUDARSAN PLANXS 2023.</h5>
                                </div>
                            </Col> */}
                            <Col md={3}  data-aos='fade-up'>
                                <div>
                                    <Link to="/">
                                        <img className="flotaleft1 img-fluid" src={LogoImg} height={80} width={280} />
                                    </Link>
                                </div>
                            </Col>
                            {/* <Col md={3}  data-aos='fade-left'>
                                 <div>
                                    <h5 className='flotaleft'> Website By <a href="https://www.tablonoir.com" target={'_blank'}>Tablo Noir</a>. Copyrights© 2023, All Rights Reserved.</h5>
                                </div> 
                            </Col> */}
                            
                        </Row>
                        <Row className="privacy-policy-row">
                            <Col md={8} className="text-left">
                            <div>
                                <h5 className='flotaleft'> Website By <a href="https://www.tablonoir.com" target={'_blank'}>Tablo Noir</a>. Copyrights © SUDARSAN PLANXS 2023, All Rights Reserved.</h5> 
                            </div> 
                            </Col>
                            <Col md={4} className="text-right">
                                <div>
                                    <Link to="/privacy-policy">
                                        <h5> Privacy Policy </h5>
                                    </Link>
                                </div> 
                            </Col> 
                            
                        </Row>
                    </Container>
                </section>



                {/* <Container fluid className="footerDiv">
                    <Row>

                        <Col md={12} lg={12}>

                            <Row className="copyRightDiv">
                                <div className='copyRightDiv2'>


                                    <button className='wherenextbutton'>Where next?</button>

                                    <div className="NavbarSP">
                                        <Navbar>

                                            <Nav>

                                                <Nav.Link as={NavLink} to='/' ><span>Home</span></Nav.Link>
                                                <Nav.Link as={NavLink} to='/about'><span>About</span></Nav.Link>
                                                <Nav.Link as={NavLink} to='/exterior'><span>Products</span></Nav.Link>
                                                <Nav.Link as={NavLink} to='/application'><span>Applications</span></Nav.Link>
                                                <Nav.Link as={NavLink} to='/blog'><span>Blogs</span></Nav.Link>
                                                <Nav.Link as={NavLink} to='/contact'><span>Contact</span></Nav.Link>

                                            </Nav>
                                        </Navbar>
                                    </div>
                                </div>


                             
                            </Row>
                        </Col>
                    </Row>
                </Container>
                <Container fluid> 
                    <div className='copyRightDiv1'>
                        <Row className="copyRightrow"> 
                            <Col md={4}>
                                <div>
                                    <h5 className='flotaleft'> © SUDARSAN PLANXS 2022.</h5>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div>
                                    <img className='flotaleft1' src={LogoImg} height={80} width={280} />
                                </div>
                            </Col>
                            <Col md={4}>
                                <div>
                                    <h5 className='flotaleft'> Website By <a href="https://www.tablonoir.com" target={'_blank'}>Tablo Noir</a>.</h5>
                                </div> 
                            </Col>
                        </Row>
                    </div> 
                </Container>  */}

            </Fragment>

        )
    }
}


export default Footer;