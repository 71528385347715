import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row } from 'bootstrap-4-react'; 
// import Swiper core and required modules
import { Navigation, Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Image1 from '../../assets/ApplicationAssets/SUDARSAN PLANX INNER Asset-04.jpg';
import Image2 from '../../assets/ApplicationAssets/SUDARSAN PLANX INNER Asset-05.jpg';
import Image3 from '../../assets/ApplicationAssets/SUDARSAN PLANX INNER Asset-06.jpg';
import { Parallax } from "react-parallax";
class ApplicationProjects extends PureComponent {

    render() {
        return (
            <Fragment>


                <section className="projects-application-sec application-page-sec">
                    <Container fluid className="projects-application-head">
                        <Row className="common-flex-right">
                            <Col md={8}>
                                <div className="common-heading" data-aos='fade-right'>
                                    <h1 className="felisBold">  Our Projects</h1> 
                                    <div className="lineclassy"></div>
                                </div>
                            </Col>
                        </Row> 
                    </Container>
                    <Container fluid className="projects-application-container" data-aos='fade-up'>
                     

                        <Row className="projects-application-row projects-row">
                            <Col md={12}>
                            <Swiper  
                                 // install Swiper modules
                            modules={[Navigation, Pagination]}
                            spaceBetween={50}  
                            loop={true}
                            slidesPerView={3}
                            navigation={true} 
                            grabCursor={true}
                            className={"applicationSwiper"}
                            breakpoints={{
                                320: {
                                  // width: 576,
                                  slidesPerView: 1,
                                },
                                 768: {
                                  // width: 768,
                                  slidesPerView: 3,
                                }, 
                              }}
                            >
                                <SwiperSlide>
                                    <div className="application-proj-img">
                                        <img src={Image1} className="img-fluid  d-md-none" />
                                        <Parallax bgImage={Image1} strength={100} className="d-md-block"></Parallax>
                                    </div>

                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="application-proj-img">
                                        <img src={Image2} className="img-fluid d-md-none" />
                                        <Parallax bgImage={Image2} strength={100} className="d-md-block"></Parallax>
                                    </div>

                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="application-proj-img">
                                        <img src={Image3} className="img-fluid d-md-none" />
                                        <Parallax bgImage={Image3} strength={100} className="d-md-block"></Parallax>
                                    </div>

                                </SwiperSlide> 

                            </Swiper>
                            </Col>
                          
                        </Row>

                      

                    </Container>
                </section>
            </Fragment>
        )
    }
}


export default ApplicationProjects