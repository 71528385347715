import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row } from 'bootstrap-4-react';
import LogoImg from '../../assets/SP_Home_Assets-02.svg';
import Image1 from '../../assets/ExteriorAssets/sample-image1.jpg';
import { Parallax } from "react-parallax";

class ExteriorExterior extends PureComponent {

  render() {
    return (
      <Fragment>


        <section className="exterior-exterior-sec">

          <Container className="exterior-exterior-container">  

              <Row className="exterior-exterior-row">
                <Col md={12}>
                    <div className="exterior-white-head"  data-aos='fade-left'>
                      <h4 className="felisBold">Exterior</h4>
                    </div>
                    <div className="exterior-img"  data-aos='fade-up'>
                      <img src={Image1} className="img-fluid d-md-none" /> 
                      <Parallax bgImage={Image1} strength={600} className="d-md-block">
                                    </Parallax>
                    </div>
                    <div className="exterior-black-head"  data-aos='fade-right'>
                      <h4 className="felisBold">Exterior</h4>
                    </div> 
                </Col>
              </Row>

              <Row className="exterior-desc-row">
                <Col md={6} className="exterior-desc-col">
                  <div className="exterior-desc-content"  data-aos='fade-right'>
                      <p>Sudarsan Planx offers more than just robust protection to your surfaces; it augments its style as well. Our range of premium, exterior planks are prudently designed keeping in mind the various needs of our customers. Choose from different profiles, colours and sizes to maximize the look and minimize product wastage! All our products are manufactured with only one goal in mind - to be durable.</p>
 
                  </div>
                </Col>
                <Col md={6} className="exterior-desc-col">
                  <div className="exterior-desc-content"  data-aos='fade-left'>
                    <p>They are made to withstand any weather or outdoor condition like heat, rain, humidity and even salinity! In addition to these features, Sudarsan Planx has retained its unique designs to give you a discernable aesthetic or a contemporary style. Our superior quality WPC planks come in two design patterns- grained and grooved, which can be used with three colour options- coffee, latte and chocolate and two different models - Planx Xl and Planx Hollow.</p>
                   
                  </div>
                </Col>
              </Row>
           
          </Container>
        </section>
      </Fragment>
    )
  }
}


export default ExteriorExterior