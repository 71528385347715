import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row } from 'bootstrap-4-react';
import Image1 from '../../assets/AboutAssets/SP_About_Assets-04.png';
import ArrowNextImage from './../../assets/SP_Home_Assets-19.svg';

class AboutWeBelieve extends PureComponent {

    render() {
        return (
            <Fragment>


                <section className="we-belive-sec">
                    <Container fluid className="we-belive-container">
                        <Row className="we-belive-row">
                            <Col md={4}>
                                <div className="we-belive-img-div"  data-aos='fade-right'>
                                    <h2>S</h2>
                                </div>
                            </Col>
                            <Col md={7}>
                                <div className="we-belive-content-div">
                                    <div className="we-belive-content-head"  data-aos='fade-up'>
                                        <h3 className="felisBold">“We believe in the transformative power of architecture to elevate everyday life.</h3>
                                    </div>
                                    <div className="we-belive-content-para">
                                        <Row>
                                            <Col md={6}>
                                                <div className="we-belive-text">
                                                    <p  data-aos='fade-up'>Thanks to its natural wood-like look and its ability to withstand severe weather conditions, WPC is fast becoming the unanimous choice for home designers. </p>
                                                    <p  data-aos='fade-up'>Our next-generation WPC plank is made from recycled and reclaimed wood fibres and polymers in an effort to help decrease the environmental pollution.</p>
                                                    <div className="we-belive-btn-div"  data-aos='fade-up'>
                                                        <a href="#">WORK WITH US</a>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="we-belive-text">
                                                    <p  data-aos='fade-down'>Sudarsan Planx's unparalleled product features and uncompromising quality has led to the creation of planxs that are virtually maintenance-free! It is UV-protected, weather-resistant, termite treated and anti-corrosive. </p>
                                                    <p data-aos='fade-down'>Our high-performance composite is not prone to warping and rotting and lasts much longer with very minimal maintenance.</p>
                                                </div>
                                            </Col>
                                        </Row>

                                    </div>

                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>


                {/* <section className="banner-about-we-believe">

                    <Row className="banner-container-we-believe">
                        <Row>
                            <Col md={4}>
                                <img src={Image1} />
                            </Col>


                            <Col md={8} className="innerTextforwebelieve">
                                <h1 className='FelisSemiBold'>“We believe in the transformative power
                                    of architecture to elevate everyday life.</h1>

                                <Row className="InnerPAenean">

                                    <Col md={5}>
                                        <p className='FelisSemiBold'>
                                            Aenean commodo ligula eget dolor.
                                            Aenean massa. Cum sociis natoque
                                            penatibus et magnis dis parturient
                                            montes, nascetur ridiculus mus.
                                            Nulla consequat massa quis enim.<br /><br />

                                            Cum sociis natoque eget magnis
                                            dis parturient montes et. Aenean
                                            leo ligula eu, consequat vitae.


                                        </p>

                                    </Col>
                                    <Col md={5}>
                                        <p className='FelisSemiBold'>
                                            Aenean commodo ligula eget dolor.
                                            Aenean massa. Cum sociis natoque
                                            penatibus et magnis dis parturient
                                            montes, nascetur ridiculus mus.
                                            Nulla consequat massa quis enim.<br /><br />

                                            Cum sociis natoque eget magnis
                                            dis parturient montes et. Aenean
                                            leo ligula eu, consequat vitae.


                                        </p>

                                    </Col>
                                    <Col md={2}></Col>
                                </Row>

                                <Row>
                                    <Col md={6}>
                                        <h3><img src={ArrowNextImage} width="80" /> WORK WITH US </h3>

                                    </Col>
                                </Row>
                            </Col>

                        </Row>

                    </Row>
                </section> */}
            </Fragment>
        )
    }
}


export default AboutWeBelieve