import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row } from 'bootstrap-4-react';

// import Swiper core and required modules
import { Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import Image1 from '../../assets/AboutAssets/SP_About_Assets-08.jpg';
import Image2 from '../../assets/AboutAssets/SP_About_Assets-09.svg';
import { Parallax } from "react-parallax";
class AboutWhatMakes extends PureComponent {

    render() {
        return (
            <Fragment>

                <section className="what-makes-about-sec">
                    <Container fluid className="what-makes-about-container wrap">
                        <Row>
                            <Col md={12}>
                                <div className="about-our-story-head" data-aos='fade-up'>
                                    <h2 className="felisBold"><span className="numberText">03</span>What makes us stand out</h2>
                                </div>
                                <Swiper modules={[Pagination]} spaceBetween={0} slidesPerView={1} loop={true} pagination={{ clickable: true }} className="what-makes-swiper" grabCursor={true}>
                                    <SwiperSlide>
                                        <Row>
                                            <Col md={5}>
                                                <div className="what-makes-swiper-content" data-aos='fade-right'>
                                                    <img src={Image2} className="img-fluid" />
                                                    <h6 className="felisBold">Durability and Quality   </h6>
                                                    <p>Sudarsan Planx is a name that is synonymous with quality, with our high-strength WPC planxs treated to withstand adverse weather conditions and wear and tear against time.</p>
                                                </div>
                                            </Col>
                                            <Col md={7}>
                                                <div className="what-makes-swiper-img" data-aos='fade-left'>
                                                    <Parallax bgImage={Image1} strength={-400} className="d-md-block"></Parallax>
                                                    <img src={Image1} className="img-fluid d-md-none" />
                                                </div>
                                            </Col>
                                        </Row>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <Row>
                                            <Col md={5}>
                                                <div className="what-makes-swiper-content" data-aos='fade-right'>
                                                    <img src={Image2} className="img-fluid" />
                                                    <h6 className="felisBold">Natural Wood Aesthetics</h6>
                                                    <p>Our planxs add an undeniable element of aesthetics to your designated space, elevating its attractiveness with the natural wood-like finish.</p>
                                                </div>
                                            </Col>
                                            <Col md={7}>
                                                <div className="what-makes-swiper-img" data-aos='fade-left'>
                                                    <Parallax bgImage={Image1} strength={-400} className="d-md-block"></Parallax>
                                                    <img src={Image1} className="img-fluid d-md-none" />
                                                </div>
                                            </Col>
                                        </Row>
                                    </SwiperSlide>
                                    <SwiperSlide>
                                        <Row>
                                            <Col md={5}>
                                                <div className="what-makes-swiper-content" data-aos='fade-right'>
                                                    <img src={Image2} className="img-fluid" />
                                                    <h6 className="felisBold">Adaptable and Versatile</h6>
                                                    <p>Our multipurpose planxs enhance the look and feel of any space - be it railings, louvers, gates, cladding, decks, pergolas, etc.</p>
                                                </div>
                                            </Col>
                                            <Col md={7}>
                                                <div className="what-makes-swiper-img" data-aos='fade-left'>
                                                    <Parallax bgImage={Image1} strength={-400} className="d-md-block"></Parallax>
                                                    <img src={Image1} className="img-fluid d-md-none" />
                                                </div>
                                            </Col>
                                        </Row>
                                    </SwiperSlide>
                                </Swiper>
                            </Col>
                        </Row>
                    </Container>

                </section>

                {/* <section className="what-makes-about">

                    <Container fluid className="what-makes-container">
                        <h1 className='whatmakesheading'>03 What makes us stand out</h1>

                        <Swiper
                            // install Swiper modules
                            modules={[Navigation, Pagination, Scrollbar, A11y]}
                            spaceBetween={50}
                            slidesPerView={1}
                            loop={true}
                            navigation
                            pagination={{ clickable: true }}
                            scrollbar={{ draggable: true }}
                            onSwiper={(swiper) => console.log(swiper)}
                            onSlideChange={() => console.log('slide change')}
                            className="what-makes-swiper"
                        >
                            <SwiperSlide>

                        

                            <div className='wmsdiv'>

                            <img src={Image2} width='120' height='80'/>
                                <h1>Natural wood Aesthetics</h1>

                                <p>Lorem ipsum dolor sit amet,
                                    consectetuer adipiscing
                                    elit. Aenean ligula eget
                                    dolor. Aenean massa. Cum
                                    sociis natoque penatibus et
                                    magnis dis parturient dis
                                    montes, nascetur commodo
                                    ridiculus mus.</p>
                                    </div>
                                <img src={Image1} className='mainimage'/>

                            </SwiperSlide>

                            <SwiperSlide>

                        

<div className='wmsdiv'>

<img src={Image2} width='120' height='80'/>
    <h1>Natural wood Aesthetics</h1>

    <p>Lorem ipsum dolor sit amet,
        consectetuer adipiscing
        elit. Aenean ligula eget
        dolor. Aenean massa. Cum
        sociis natoque penatibus et
        magnis dis parturient dis
        montes, nascetur commodo
        ridiculus mus.</p>
        </div>
    <img src={Image1} className='mainimage'/>

</SwiperSlide>


<SwiperSlide>

                        

<div className='wmsdiv'>

<img src={Image2} width='120' height='80'/>
    <h1>Natural wood Aesthetics</h1>

    <p>Lorem ipsum dolor sit amet,
        consectetuer adipiscing
        elit. Aenean ligula eget
        dolor. Aenean massa. Cum
        sociis natoque penatibus et
        magnis dis parturient dis
        montes, nascetur commodo
        ridiculus mus.</p>
        </div>
    <img src={Image1} className='mainimage'/>

</SwiperSlide>


<SwiperSlide>

                        

<div className='wmsdiv'>

<img src={Image2} width='120' height='80'/>
    <h1>Natural wood Aesthetics</h1>

    <p>Lorem ipsum dolor sit amet,
        consectetuer adipiscing
        elit. Aenean ligula eget
        dolor. Aenean massa. Cum
        sociis natoque penatibus et
        magnis dis parturient dis
        montes, nascetur commodo
        ridiculus mus.</p>
        </div>
    <img src={Image1} className='mainimage'/>

</SwiperSlide>



                        </Swiper>
                    </Container>
                </section> */}
            </Fragment>
        )
    }
}


export default AboutWhatMakes;