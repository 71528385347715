import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row } from 'bootstrap-4-react';
import { Parallax } from "react-parallax";
import image1 from './../../assets/ExteriorAssets/SP_Banner_Assets-02.jpg';
class InteriorBanner extends PureComponent {

  render() {
    return (
      <Fragment>


        <Parallax className="bannerSec exteriorBanner" bgImage={image1} strength={200}>
          <Container fluid className="banner-container">
            <Row>
              <Col md={7}>
                <div className="banner-head">
                  <h2 className="felisSemiBold" data-aos='fade-up'>Customizable ingenious creations,
                    that last a lifetime!</h2>
                </div>
              </Col>
            </Row>
          </Container>
        </Parallax>
      </Fragment>
    )
  }
}


export default InteriorBanner