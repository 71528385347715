import React, { PureComponent, Fragment } from "react";
import { Container, Col, Row, Button } from "bootstrap-4-react";
import Form from "react-bootstrap/Form";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import * as apiUrl from "./../../apiUrl";
import Modal from 'react-bootstrap/Modal';
import axios from "axios";
import Reaptcha from "reaptcha";

class ContactContactUs extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      formmodal: false,
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      message: "",
      errors: {},
      captcha: null,
    };

    this.validate = this.validate.bind(this);

    this.Setfirstname = this.Setfirstname.bind(this);
    this.Setlastname = this.Setlastname.bind(this);
    this.Setphone = this.Setphone.bind(this);
    this.Setemail = this.Setemail.bind(this);
    this.Setmessage = this.Setmessage.bind(this);

    this.validateform123 = this.validateform123.bind(this);
    this.ContactForm = this.ContactForm.bind(this);

    this.ThankYouModalclose = this.ThankYouModalclose.bind(this);
    this.ThankYouModalopen = this.ThankYouModalopen.bind(this);
    this.onChangeBanner = this.onChangeBanner.bind(this);
  }

  onChangeBanner(value) {
    window.localStorage.setItem("ContactFormCaptcha", value);
    let errors = this.state.errors;
    errors["Captcha123"] = "";

    this.setState({ errors: errors });
  }
  Setfirstname = (event) => {
    this.setState({ firstname: event.target.value });

    let errors = this.state.errors;
    errors["firstname"] = "";

    this.setState({ errors: errors });
  };

  Setlastname = (event) => {
    this.setState({ lastname: event.target.value });

    let errors = this.state.errors;
    errors["lastname"] = "";

    this.setState({ errors: errors });
  };

  Setemail = (event) => {
    this.setState({ email: event.target.value });

    let errors = this.state.errors;
    errors["email"] = "";

    this.setState({ errors: errors });
  };

  Setphone = (event) => {
    this.setState({ phone: event.target.value });

    let errors = this.state.errors;
    errors["phone"] = "";

    this.setState({ errors: errors });
  };

  Setmessage = (event) => {
    this.setState({ message: event.target.value });

    let errors = this.state.errors;
    errors["message"] = "";

    this.setState({ errors: errors });
  };

  validate = (evt) => {
    var theEvent = evt || window.event;

    // Handle paste
    if (theEvent.type === "paste") {
      key = evt.clipboardData.getData("text/plain");
    } else {
      // Handle key press
      var key = theEvent.keyCode || theEvent.which;
      key = String.fromCharCode(key);
    }
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      theEvent.returnValue = false;
      if (theEvent.preventDefault) theEvent.preventDefault();
    }
  };

  onlyAlphabets = (evt) => {
    try {
      if (window.event) {
        var charCode = window.event.keyCode;
      } else if (evt) {
        var charCode = evt.which;
      } else {
        return true;
      }
      if (
        (charCode > 64 && charCode < 91) ||
        (charCode > 96 && charCode < 123) ||
        charCode == 32
      )
        return true;
      else evt.preventDefault();
    } catch (err) {
      alert(err.Description);
    }
  };

  validateform123() {
    let firstname = this.state.firstname;
    let lastname = this.state.lastname;   

    let email = this.state.email;
    let phone = this.state.phone;

    let message = this.state.message;

    let errors = {};
    let formIsValid = true;

    let token = window.localStorage.getItem("ContactFormCaptcha");

    // console.log(token);

    if (token === null || token === "null") {
      formIsValid = false;
      errors["Captcha123"] = "Please Select The Captcha";
    }

    

    if (firstname == "") {
      formIsValid = false;
      errors["firstname"] = "Please Enter Your First Name";
    }

    if (lastname == "") {
      formIsValid = false;
      errors["lastname"] = "Please Enter Your Last Name";
    }

    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    if (!this.state.email || regex.test(this.state.email) === false) {
      errors["email"] = "Email is not valid";
      this.setState({
        errors: errors,
      });
      formIsValid = false;
    }
    if (email == "") {
      formIsValid = false;
      errors["email"] = "Please Enter The Email Address";
    }

    if (phone == "") {
      formIsValid = false;
      errors["phone"] = "Please Enter The Mobile Number";
    }
    if (phone.length < 10 || phone.length > 10) {
      formIsValid = false;
      errors["phone"] = "Please Enter a Valid Mobile Number";
    }

    if (message == "") {
      formIsValid = false;
      errors["message"] = "Please Enter The Message";
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  async ContactForm() {
    const self = this;
    // this.validateform123();

    if (this.validateform123()) {
      self.setState({ loading: true });

      let token = window.localStorage.getItem("ContactFormCaptcha");

      // console.log(token);

      const obj = {
        firstname: this.state.firstname,
        lastname: this.state.lastname,
        email: this.state.email,
        phone: this.state.phone,
        message: this.state.message,
        token: token,
      };

      // console.log(obj);

      axios
        .post(apiUrl.apiUrl , obj)
        // .post(apiUrl.apiUrl + "/sudarsanapi/phpmailer.php", obj)
        .then((res) => console.log(res.data))
        .catch((error) => {
          console.log(error.response);
        });

      self.ThankYouModalopen();

      self.setState({
        firstname: "",
        lastname: "",
        email: "",
        phone: "",       
        message: "",
      });

      // this.captcha.reset();

      window.grecaptcha.reset();

      window.localStorage.removeItem("ContactFormCaptcha");
    }
  }

  ThankYouModalclose() {
    this.setState({ ThankYou: false });
    }
    ThankYouModalopen() {
        this.setState({ ThankYou: true });
    }
   


  render() {
    return (
      <Fragment>
        <section className="sp-contact-sec">
          <Container fluid>
            <Row className="common-flex-center">
              <Col md={6}>
                <div className="common-heading">
                  <h3 className="felisBold" data-aos="fade-up">
                    {" "}
                    Contact Us{" "}
                  </h3>
                  <p data-aos="fade-up">
                    We’d love to hear from you! Please send us your doubts and
                    queries, and we will get back to you shortly!
                  </p>
                </div>
              </Col>
            </Row>
          </Container>

          <Container fluid className="contact-us-form-container">
            <Row className="contact-us-contact-row">
              <Col md={3}>
                <div className="visitOfz" data-aos="fade-right">
                  <h3 className="felisRegular">Visit Us</h3>

                  <p>(256) 477, Sydenhams Road, Chennai - 600 112</p>
                  <p>
                    <b>T</b>: +9144 2669 2244
                  </p>

                  <p>
                    <b>M</b>: +91 91765 77724
                  </p>

                  <p>
                    <b>E</b>: srivatsa16@gmail.com
                  </p>
                  <p>
                    <b> W</b>: www.sudarsanplanx.com
                  </p>
                  <div className="social-link">
                    <a
                      href="#"
                      alt="Facebook"
                      target="_blank"
                      className="icon-facebook"
                    >
                      <FaFacebookF />
                    </a>
                    <a
                      href="#"
                      alt="Facebook"
                      target="_blank"
                      className="icon-facebook"
                    >
                      <FaTwitter />
                    </a>
                    <a
                      href="#"
                      alt="Facebook"
                      target="_blank"
                      className="icon-facebook"
                    >
                      <FaLinkedinIn />
                    </a>
                  </div>
                </div>
              </Col>

              <Col md={8} data-aos="fade-left">
                <Form>
                  <fieldset>
                    <Form.Group className="mb-3">
                      <Row>
                        <Col md={6}>
                          {/* <Form.Label htmlFor="firstname">First Name</Form.Label> */}
                          <Form.Control
                            id="firstname"
                            placeholder="First Name"
                            value={this.state.firstname}
                            onChange={this.Setfirstname}
                            onKeyPress={this.onlyAlphabets}
                          />
                          <span className="form-error">
                            {" "}
                            {this.state.errors["firstname"]}{" "}
                          </span>
                        </Col>
                        <Col md={6}>
                          {/* <Form.Label htmlFor="lastname">Last Name</Form.Label> */}
                          <Form.Control
                            id="lastname"
                            placeholder="Last Name"
                            value={this.state.lastname}
                            onChange={this.Setlastname}
                            onKeyPress={this.onlyAlphabets}
                          />
                          <span className="form-error">
                            {" "}
                            {this.state.errors["lastname"]}{" "}
                          </span>
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Row>
                        <Col md={6}>
                          {/* <Form.Label htmlFor="email">Email</Form.Label> */}
                          <Form.Control
                            id="email"
                            placeholder="Email"
                            value={this.state.email}
                            onChange={this.Setemail}
                          />
                          <span className="form-error">
                            {" "}
                            {this.state.errors["email"]}{" "}
                          </span>
                        </Col>
                        <Col md={6}>
                          {/* <Form.Label htmlFor="phone">Phone</Form.Label> */}
                          <Form.Control
                            id="phone"
                            placeholder="Phone"
                            value={this.state.phone}
                            onChange={this.Setphone}
                            onKeyPress={this.validate}
                          />
                          <span className="form-error">
                            {" "}
                            {this.state.errors["phone"]}{" "}
                          </span>
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Row>
                        <Col md={12}>
                          {/* <Form.Label htmlFor="message">Message</Form.Label> */}
                          <Form.Control
                            as="textarea"
                            rows={10}
                            id="message"
                            placeholder="Message"
                            value={this.state.message}
                            onChange={this.Setmessage}
                          />
                          <span className="form-error">
                            {" "}
                            {this.state.errors["message"]}{" "}
                          </span>
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Row>
                        <Col md={12}>
                          <Reaptcha
                            sitekey="6LeJlhwmAAAAADUC43tLLJjSX_tvNm7oeEMbdjDj"
                            onVerify={this.onChangeBanner}
                            ref={(e) => (this.Captcha = e)}
                          />{" "}
                          <span className="form-error">
                            {this.state.errors["Captcha123"]}{" "}
                          </span>
                        </Col>
                      </Row>
                    </Form.Group>
                  </fieldset>

                  <Row className="common-flex-center" data-aos="fade-up">
                    <Col md={12}>
                      <div className="insights-button-div">
                        <div className="lets-talk-button">
                          <Button
                            type="button"
                            className="spButton"
                            onClick={this.ContactForm}
                          >
                            SUBMIT
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Col>

              <Col md={1}></Col>
            </Row>
          </Container>

          <Container fluid>
            <Row className="iframemaps common-flex-center" data-aos="fade-up">
              <Col md={10}>
                <iframe
                  className="map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.1132399120047!2d80.26722271534375!3d13.092009115765732!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5265fa41501903%3A0x49789ef89b665159!2s477%2C%20Raja%20Muthiah%20Rd%2C%20Thattankulam%2C%20Choolai%2C%20Chennai%2C%20Tamil%20Nadu%20600112!5e0!3m2!1sen!2sin!4v1658996181158!5m2!1sen!2sin"
                  width="800"
                  height="350"
                ></iframe>
              </Col>
            </Row>
          </Container>
        </section>

        <div>
                    <Modal show={this.state.ThankYou} onHide={() => this.ThankYouModalclose()} size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered className="page-load-modal">
                        <Modal.Header className="page-load-modal-header" closeButton > </Modal.Header>

                        <Modal.Body className="page-load-modal">

                            <div className="container first-modal-container">
                                <div className="row first-modal-row">
                                    <div className="col-md-12">
                                        <div className="first-modal-content">
                                            <div className="first-modal-heading">
                                                <h5 id="ThankYouMessage">Form Submitted Successfully</h5>
                                            </div>
                                            <div className="first-modal-form">

 
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div> 
                        </Modal.Body>

                    </Modal>
                </div>
      </Fragment>
    );
  }
}

export default ContactContactUs;
