import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row } from 'bootstrap-4-react';
import { Link } from 'react-router-dom';
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Parallax } from "react-parallax";
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Image1 from '../../assets/SP_Home_Assets-25.jpg';
import Image2 from '../../assets/SP_Home_Assets-26.jpg';
import Image3 from '../../assets/SP_Home_Assets-27.jpg';


class LatestInsights extends PureComponent {

    render() {
        return (
            <Fragment>


                <section className="sp-latest-insights">
                    <Container fluid data-aos='fade-right'>
                        <Row className="common-flex-left">
                            <Col md={8}>
                                <div className="common-heading">
                                    <h1 className='felisBold'> Latest Insights </h1>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <Container fluid className="sp-insights-container" data-aos='fade-up'>
                        <Row>
                            <Col md={12}>
                                <div className="insights-swiper-div">
                                    <Swiper className='insights-swiper'
                                        loop={true}
                                        centeredSlides={true}
                                        breakpoints={{
                                            640: {
                                                slidesPerView: 1,
                                                spaceBetween: 20,
                                            },
                                            768: {
                                                slidesPerView: 2,
                                                spaceBetween: 50,
                                                centeredSlides: false,
                                            },
                                            1024: {
                                                slidesPerView: 2,
                                                spaceBetween: 100,
                                                centeredSlides: false,
                                            },
                                            1280: {
                                                slidesPerView: 3,
                                                spaceBetween: 100,
                                            },
                                        }}
                                        modules={[Pagination]}>
                                        <SwiperSlide className='insights-swiper-slide'>
                                            <div className="swiper-slide-div">
                                                <Link to="/wpc">
                                                    <Parallax bgImage={Image1} strength={100} className="d-md-block">
                                                </Parallax>
                                                    <img src={Image1} alt="LeftIMG" className="img-fluid d-md-none" />
                                                    <div className="con-div">
                                                        <h1 className='felisRegular'>WPC Vs. Natural Wood - Which Is Better?</h1>
                                                        <p>WPC planks are wood-plastic composites that are composed of materials like wood fibre and thermoplastics.</p>
                                                    </div>
                                                    <div className="center-circle-div">
                                                        <p>DRAG</p>
                                                    </div>
                                                </Link>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide className='insights-swiper-slide'>
                                            <div className="swiper-slide-div">
                                                <Link to="/why-choose-sp">
                                                <Parallax bgImage={Image2} strength={100} className="d-md-block">
                                                </Parallax>
                                                    <img src={Image2} alt="LeftIMG" className="img-fluid d-md-none" />
                                                    <div className="con-div">
                                                        <h1 className='felisRegular'>Why Choose Sudarsan Planks?</h1>
                                                        <p>The look and feel of Sudarsan Planx is unrivalled to any other type of planks in the market.</p>
                                                    </div>
                                                    <div className="center-circle-div">
                                                        <p>DRAG</p>
                                                    </div>
                                                </Link>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide className='insights-swiper-slide' >
                                            <div className="swiper-slide-div">
                                                <Link to="/process-of-laying">
                                                <Parallax bgImage={Image3} strength={100} className="d-md-block">
                                                </Parallax>
                                                    <img src={Image3} alt="LeftIMG" className="img-fluid d-md-none" />
                                                    <div className="con-div">
                                                        <h1 className='felisRegular'>Process Of Laying A Deck Using The Planks.</h1>
                                                        <p>WPC is the perfect choice for pool decking because it is waterproof. </p>
                                                    </div>
                                                    <div className="center-circle-div">
                                                        <p>DRAG</p>
                                                    </div>
                                                </Link>
                                            </div>
                                        </SwiperSlide>

                                    </Swiper>
                                </div>
                            </Col>
                        </Row>
                        <Row className="common-flex-center">
                            <Col md={12}>
                                <div className="insights-button-div">
                                    <div className="lets-talk-button">
                                        <Link to="/blog"><button className='spButton'>View All</button></Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Fragment>
        )
    }
}


export default LatestInsights