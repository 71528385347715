import React, { PureComponent, Fragment } from 'react';

import Header from '../../../common/header';
import Footer from '../../../common/footer'; 

import LetsTalk from '../../../components/blog-component/lets-talk';
import WpcBanner from '../../../components/blog-component/wpc/wpc-banner';
import WpcMain from '../../../components/blog-component/wpc/wpc-main';
import WpcLatestBlogs from '../../../components/blog-component/wpc/wpc-latest-blogs';
import {Helmet} from "react-helmet";

class WpcPage extends PureComponent {
    render() {
        return (

            <Fragment>

                <Helmet> 
                    <title>WPC Vs. Natural Wood - Which Is Better? | Blog Post</title>
                    <meta name="description" content="In this blog, we delve into the differences between WPC and natural wood and explain in depth the various advantages of WPC over wood."></meta>
                    <link rel="canonical" href="https://www.sudarsanplanx.com/wpc" />
                </Helmet> 

 
                <Header />
                
                <WpcBanner />

                <WpcMain />

                <WpcLatestBlogs />

                <LetsTalk />

                <Footer />

            </Fragment>
        )

    }
}

export default WpcPage