

import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row } from 'bootstrap-4-react';
import Image1 from '../../assets/SP_Home_Assets-29.jpg';
import { Parallax, Background } from "react-parallax";
import { Link } from 'react-router-dom'; 
const container = {
    hidden: {
        opacity: 0,
    },
    show: {
        opacity: 1,

        transition: {
            delayChildren: 2,
            staggerChildren: 0.3,
        }
    }

}

const item = {
    hidden: {
        opacity: 0,
    },
    show: {
        opacity: 1,
    }

}

class LetsTalk extends PureComponent {

    render() {
        return (
            <Fragment>
                {/* <section className="lets-talk">

                    <Container fluid className="lets-talk-container">
                        <Row>
                            <h1 className='AktivGroteskBold'>Share your<br />
                                project with us!</h1>

                            <img src={Image1} className="letstalkimg"/>

                            <button className='letstalkbutton FelisBold'>Lets Talk!</button>
                        </Row>



                    </Container>

                </section> */}

                <section className="lets-talk-sec">
                    <Container fluid className="lets-talk-container">
                        <Row className="lets-talk-row">
                            <Col md={10}>
                                <div className="lets-talk-img" data-aos='fade-left'>
                                    <Parallax bgImage={Image1} strength={750} className="d-md-block"></Parallax>
                                    <img src={Image1} className="letstalkimg d-md-none" alt="lets-talk-img" />
                                </div>
                                <div className="lets-talk-text" data-aos='fade-right'>
                                    <h1 className='felisRegular'>Share your
                                        project with us!</h1>
                                </div>
                                {/* <div className="lets-talk-button">
                                    <button className='spButton'>Lets Talk!</button>
                                </div> */}
                                <div className="lets-talk-button">
                                    <Link to="/contact" className="spButton">Let's Talk! </Link>
                                </div>
                                 
                            </Col>
                        </Row>
                    </Container>
                </section>

            </Fragment>
        )
    }
}


export default LetsTalk;