import React, { PureComponent, Fragment } from 'react';
import Footer from '../../common/footer';
import Header from '../../common/header';
import PricacyBanner from './privacy-policy-banner'; 
import PrivacyDescriptions from './privacy-policy-descriptions'; 
import {Helmet} from "react-helmet";

class PrivacyPage extends PureComponent{
 
 
  render(){

      return( 
        <Fragment>

          <Helmet> 
                    <title>Sudarsan WPC Planx - Privacy Policy </title>
                    <meta name="description" content="Sudarsan WPC Planx - Privacy Policy"></meta>
                    <link rel="canonical" href="https://www.sudarsanplanx.com/privacy-policy" />
          </Helmet> 

          <Header/>

          <PricacyBanner/>   
          <PrivacyDescriptions />
          <Footer />
        </Fragment>
      )

  }
}

export default PrivacyPage