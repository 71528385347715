import React, { PureComponent, Fragment } from 'react';

import Header from '../../../common/header';
import Footer from '../../../common/footer'; 

import LetsTalk from '../../../components/blog-component/lets-talk';
import ProcessBanner from './../process-of-laying/process-of-laying-banner';
import ProcessoflayingMain from './process-of-laying-main';
import ProcessLatestBlogs from './process-of-laying-latest-blogs';
import {Helmet} from "react-helmet";

class ProcessoflayingPage extends PureComponent {
    render() {
        return (

            <Fragment>

                <Helmet> 
                    <title>Process of laying a deck using the planks | Blog Post</title>
                    <meta name="description" content="We explain the step-by-step process of laying a deck using our planks. Read more about our tips & tricks to preserve our planks for an extended lifetime."></meta>
                    <link rel="canonical" href="https://www.sudarsanplanx.com/process-of-laying" />
                </Helmet> 
 
                <Header />

                <ProcessBanner />

                <ProcessoflayingMain />

                <ProcessLatestBlogs />

                <LetsTalk />

                <Footer />

            </Fragment>
        )

    }
}

export default ProcessoflayingPage