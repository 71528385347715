// import React, { Fragment } from 'react';
// import { Swiper, SwiperSlide } from "swiper/react";
// import SwiperCore, { Navigation, Thumbs, Mousewheel, Pagination, Autoplay } from "swiper";
// import { Container, Col, Row } from 'bootstrap-4-react';
// import 'swiper/css';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';
// import { useState } from "react";

// import amitiesImage1 from './../../assets/AboutAssets/SP_About_Assets-06.jpg';
// import amitiesImage2 from './../../assets/AboutAssets/SP_About_Assets-06.jpg';
// import amitiesImage3 from './../../assets/AboutAssets/SP_About_Assets-06.jpg';
// import amitiesImage4 from './../../assets/AboutAssets/SP_About_Assets-06.jpg';
// import amitiesImage5 from './../../assets/AboutAssets/SP_About_Assets-06.jpg';


// SwiperCore.use([Autoplay]);
// export default function AboutHowWeWork() {
//     const [imagesNavSlider, setImagesNavSlider] = useState(null);
//     return (
//         <Fragment>
//             <section className="abouthowwework-sec slider" id="abouthowwework-sec" >



//             </section>
//         </Fragment>
//     );
// }



import React, { Fragment, PureComponent } from 'react'; 
import { Container, Row, Col } from 'react-bootstrap';

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import amitiesImage1 from './../../assets/new-imgs/conceptualise.jpg';
import amitiesImage2 from './../../assets/new-imgs/tailor.jpg';
import amitiesImage3 from './../../assets/new-imgs/end-product.jpg';

import { Parallax } from "react-parallax";
class AboutHowWeWork extends PureComponent {

    render() {
        return (
            <Fragment>
                <section className="how-we-work-sec">
                    <Container fluid className="how-we-work-container wrap">
                        <Row className="how-we-work-row">
                            <Col md={12}>
                                <div className="stickyPosition">
                                    <div className="sticky-nav-tabs">
                                        <Tab.Container id="left-tabs-example" defaultActiveKey="creativeplan">
                                            <Row>
                                                <Col sm={5}>
                                                    <div className="stickyPosition">
                                                        <div className="sticky-nav-tabs"  data-aos='fade-up'>
                                                            <div className="about-our-story-head">
                                                                <h2 className="felisBold"><span className="numberText">02</span>How we work</h2>
                                                            </div>
                                                            <Nav variant="pills" className="flex-column">
                                                                <Nav.Item>
                                                                    <Nav.Link eventKey="creativeplan">Conceptualise</Nav.Link>
                                                                </Nav.Item>
                                                                <Nav.Item>
                                                                    <Nav.Link eventKey="tailormade">Tailor Make</Nav.Link>
                                                                </Nav.Item>
                                                                <Nav.Item>
                                                                    <Nav.Link eventKey="concept">End Product</Nav.Link>
                                                                </Nav.Item>
                                                                {/* <Nav.Item>
                                                                    <Nav.Link eventKey="finaldesign">Final Design</Nav.Link>
                                                                </Nav.Item> */}
                                                            </Nav>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col sm={6}>
                                                    <Tab.Content>
                                                        <Tab.Pane eventKey="creativeplan">
                                                            <div className="how-we-work-content-div"  data-aos='fade-left'>
                                                                <div className="how-we-work-content-img">
                                                                <Parallax bgImage={amitiesImage1} strength={-600} className="d-md-block"></Parallax>
                                                                    <img src={amitiesImage1} className="img-fluid d-md-none" alt="img" />
                                                                    <div className="how-we-work-content-div">
                                                                        <span className="numberText felisBold">01</span>
                                                                        <h6 className="felisBold">Conceptualise</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="how-we-work-content-para">
                                                                        <p>Each plank we design has to be multifunctional, serving its purpose adequately in every setting. So, we brainstorm ways to guarantee our products withstand not just the test of time but also enhance the aesthetical value of a space.</p>
                                                                </div>
                                                            </div>
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="tailormade">
                                                            <div className="how-we-work-content-div"  data-aos='fade-left'>
                                                                <div className="how-we-work-content-img">
                                                                    <img src={amitiesImage2} className="img-fluid d-md-none" alt="img" />
                                                                    <Parallax bgImage={amitiesImage2} strength={-600} className="d-md-block"></Parallax>
                                                                    <div className="how-we-work-content-div">
                                                                        <span className="numberText felisBold">02</span>
                                                                        <h6 className="felisBold">Tailor Make</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="how-we-work-content-para">
                                                                        <p> Our planxs are then manufactured to suit the specifications of each space, with care taken to ensure that the quality, utility, functionality, and aesthetics of the planxs are uniform throughout. </p>
                                                                </div>
                                                            </div>
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="concept">
                                                            <div className="how-we-work-content-div"  data-aos='fade-left'>
                                                                <div className="how-we-work-content-img">
                                                                    <img src={amitiesImage3} className="img-fluid d-md-none" alt="img" />
                                                                    <Parallax bgImage={amitiesImage3} strength={-600} className="d-md-block"></Parallax >
                                                                    <div className="how-we-work-content-div">
                                                                        <span className="numberText felisBold">03</span>
                                                                        <h6 className="felisBold">End Product</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="how-we-work-content-para">
                                                                        <p>The end product results in beautiful, UV-protected, weather-resistant, termite treated and anti-corrosive planxs that come in various colours, surface finishes and sizes for you to choose from to enhance the look and feel of your space.</p>
                                                                </div>
                                                            </div>
                                                        </Tab.Pane>
                                                        {/* <Tab.Pane eventKey="finaldesign">
                                                            <div className="how-we-work-content-div"  data-aos='fade-left'>
                                                                <div className="how-we-work-content-img">
                                                                    <img src={amitiesImage1} className="img-fluid" alt="img" />
                                                                    <div className="how-we-work-content-div">
                                                                        <span className="numberText felisBold">04</span>
                                                                        <h6 className="felisBold">Final Design</h6>
                                                                    </div>
                                                                </div>
                                                                <div className="how-we-work-content-para">
                                                                        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Explicabo velit maxime suscipit, nisi repellat ex optio ipsa a nulla totam nostrum eos ratione sequi laborum ducimus, unde nihil vitae accusamus?</p>
                                                                </div>
                                                            </div>
                                                        </Tab.Pane> */}
                                                    </Tab.Content>
                                                </Col>
                                            </Row>
                                        </Tab.Container>

                                    </div>
                                </div>
                            </Col>

                        </Row>
                    </Container>


                </section>
            </Fragment>
        )
    }
}
export default AboutHowWeWork

