import React, { PureComponent, Fragment } from "react";
import { Container, Col, Row } from "bootstrap-4-react";
import Image1 from "../../assets/SP_Home_Assets-04.jpg";
import Image2 from "../../assets/SP_Home_Assets-05.jpg";
import { Parallax } from "react-parallax";
class AboutOurStory extends PureComponent {
    render() {
        return (
            <Fragment>
                <section className="sp-our-story-sec about-our-story-sec">

                    <Container fluid className="wrap sp-our-story-container">

                        <Row className="sp-our-story-head-row">
                            <Col md={5} className="about-our-story-head-col" data-aos='fade-right'>
                                <div className="about-our-story-head">
                                    <h2 className="felisBold"><span className="numberText">01</span>Our Story</h2>
                                </div>
                            </Col>
                            <Col md={7} className="about-our-story-para-col" data-aos='fade-left'>
                                <div className="about-our-story-para">
                                    <p>Sudarsan Planx began with the drive to bridge personal style with environment-friendly planxs to offer the best to both worlds! Our planxs are engineered to flatter the design development of various applications by evaluating their utility, functionality and aesthetics. </p>
                                </div>
                            </Col>
                        </Row>
                        <Row className="sp-our-story-image-row">
                            <Col md={4}>
                                <div className="left-img" data-aos='fade-right'>
                                    <img src={Image1} className="img-fluid d-md-none" alt="LeftIMG" />
                                    <Parallax bgImage={Image1} strength={300} className="d-md-block">
                                    </Parallax>

                                </div>

                            </Col>
                            <Col md={8}>
                                <div className="right-img" data-aos='fade-left'>
                                    <img src={Image2} className="img-fluid d-md-none" alt="RightIMG" />
                                    <Parallax bgImage={Image2} strength={300} className="d-md-block">
                                    </Parallax>

                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Fragment>
        );
    }
}

export default AboutOurStory;
