import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row } from 'bootstrap-4-react'; 

class BlogBanner extends PureComponent {

  render() {
    return (
      <Fragment>


        <section className="bannerSec blogInnerBanner">

          <Container fluid className="blog-banner-container"> 
              <Row className="common-flex-center">
                  <Col md={8}>
                        <h4  data-aos='fade-up'>Lorem ipsum dolor sit amet,
consectuer adipiscing elit.</h4>
<p className="blog-date"  data-aos='fade-up'>14 JUL ‘22</p>
                  </Col>
              </Row>
          </Container>
        </section>
      </Fragment>
    )
  }
}


export default BlogBanner