import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row } from 'bootstrap-4-react';
import Image1 from '../../../assets/BlogInnerPage/third-blog-1st-image.jpg';
import Image2 from '../../../assets/BlogInnerPage/third-blog-2nd-image.jpg';
import blogImage4 from '../../../assets/BlogInnerPage/SP_Blogs_assets-11.jpg';
import blogImage6 from '../../../assets/BlogInnerPage/SP_Blogs_assets-12.jpg';
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
class ProcessoflayingMain extends PureComponent {

    render() {
        return (
            <Fragment>
                <section className="blog-inner-description-sec">
                    <Container fluid className="blog-inner-description-container">
                        <Row className="blog-inner-description-row">
                            <Col md={8}>
                                <div className="blog-inner-desc-text">
                                    <p data-aos='fade-right'>WPC is the perfect choice for pool decking because it is waterproof. Traditional wood decking are known to swell and warp when exposed to moisture and standing water. Therefore, Sudarsan Planx’s WPC planks has more value-for-money in the long run.</p>
                                </div>

                            </Col>
                        </Row>
                        <Row className="blog-inner-description-row">
                            <Col md={12}>
                                <div className="blog-inner-desc-img">
                                    <div className="blogs-img" data-aos='fade-left'>
                                        <img src={Image1} className="img-fluid" alt="blog-inner-img" />
                                    </div>
                                </div>

                            </Col>
                        </Row>
                        <Row className="blog-inner-description-row">
                            <Col md={8}>
                                <div className="blog-inner-desc-text">
                                    <p data-aos='fade-right'>We provide planks for decking and tiles in colour options that will complement the look of your space effortlessly. Install a beautiful deck with our Planx Hollow and ensure a water leakage, rot and decay free space that will jazz up your house!</p>

                                    <p data-aos='fade-right'>It takes us only a few hours to get your deck up and running! And we won’t damage the beauty of your spaces at all! And most importantly they are easy to install and last long with very low maintenance.</p>

                                     <ol>
                                        <li data-aos='fade-right'>Build up the flat ground by evenly adding adequate amounts of cement. </li>
                                        <li data-aos='fade-right'>Carefully install the joist over the ground and drill it into place. </li>
                                        <li data-aos='fade-right'>After installing the joist over the required area, begin to install the Planx and screw it into place. </li>
                                        <li data-aos='fade-right'>Repeat the process of installing the joists for the remaining Planx.</li>
                                        <li data-aos='fade-right'> Add the Planx for the remaining, and repeat the process to install the entire deck. </li>
                                     </ol>

                                     <p data-aos='fade-right'>Here are a few things you can do to ensure that Sudarsan Planx has the longest life possible. </p>

                                     <ol>
                                        <li data-aos='fade-right'>Do not chisel or nail into the Planx.  </li>
                                        <li data-aos='fade-right'>Do not exceed a 750mm span of space between the frames. </li>
                                        <li data-aos='fade-right'>Do not use paint or any coating.  </li>
                                        <li data-aos='fade-right'>Do not sand the edges or the surface of the Planx. </li>
                                        <li data-aos='fade-right'>To clean the Planx, the use of water and mild detergent is advisable.</li>
                                     </ol>

                                </div>

                            </Col>
                        </Row>
                        <Row className="blog-inner-description-row">
                            <Col md={12}>
                                <div className="blog-inner-desc-img">
                                    <div className="blogs-img" data-aos='fade-left'>
                                        <img src={Image2} className="img-fluid" alt="blog-inner-imgs" />
                                    </div>
                                </div>

                            </Col>
                        </Row>
                        <Row className="blog-inner-description-row">
                            <Col md={8}>
                                <div className="blog-inner-desc-border-bottoom">

                                </div>

                            </Col>
                        </Row>
                        <Row className="blog-inner-description-row">
                            <Col md={8}>
                                <div className="blog-inner-social-share">
                                    <div class="flex-social-div">
                                        <p>Share</p>
                                        <a href="#"><FaFacebookF /></a>
                                        <a href="#"><FaTwitter /></a>
                                        <a href="#"><FaLinkedinIn /></a>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                    </Container>


                </section>
            </Fragment>
        )
    }
}


export default ProcessoflayingMain



