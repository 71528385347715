import React, { PureComponent, Fragment } from 'react';

import Header from './../../common/header';
import Footer from '../../common/footer'; 
import BlogBanner from '../../components/blog-component/blog-banner';
import LatestBlogs from '../../components/blog-component/latest-blogs';
import LetsTalk from '../../components/blog-component/lets-talk';
import {Helmet} from "react-helmet";

class BlogPage extends PureComponent {
    render() {
        return (

            <Fragment>

                <Helmet> 
                    <title>Our Blogs | Sudarsan Planx</title>
                    <meta name="description" content="Our blogs give in-depth information regarding our products and their various advantages. Read our blogs to know more about Sudarsan Planx."></meta>
                    <link rel="canonical" href="https://www.sudarsanplanx.com/blog" />
                </Helmet> 
 
                <Header />

                <BlogBanner />

                <LatestBlogs />

                <LetsTalk />

                <Footer />

            </Fragment>
        )

    }
}

export default BlogPage