import React, { PureComponent, Fragment } from 'react';


import Footer from '../../common/footer';
import Header from '../../common/header';
import ContactBanner from '../../components/contact-component/contact-banner';
import ContactContactUs from '../../components/contact-component/contact-contact-us';
import {Helmet} from "react-helmet";

class ContactPage extends PureComponent {
    render() {
        return (

            <Fragment>

                <Helmet> 
                    <title>Contact Sudarsan Planx - For Best WPC Planxs, WPC Louvers & WPC Gate </title>
                    <meta name="description" content="Sudarsan Planx offers more than just robust protection to your surfaces; it augments its style as well. Contact Now for the best price in town!"></meta>
                    <link rel="canonical" href="https://www.sudarsanplanx.com/contact" />
                </Helmet> 

                <Header />

                <ContactBanner />

                <ContactContactUs />


                <Footer />

            </Fragment>
        )

    }
}

export default ContactPage