import React, { PureComponent, Fragment } from 'react'; 
import { Container, Col, Row } from 'bootstrap-4-react';
import { Parallax } from "react-parallax";
import image1 from './../../assets/AboutAssets/SP_About_Assets-01.jpg';
class PricacyBanner extends PureComponent {
 
    render() { 
        return ( 
            <Fragment>   
                {/* <section className="bannerSec contactBanner" id="privacybanner-sec">
                  <h4 className="mondiaBold">Privacy Policy</h4>
                </section> */}
                <Parallax className="bannerSec contactBanner">
                  <Container fluid className="banner-container">
                    <Row>
                      <Col md={7}>
                        <div className="banner-head">
                          <h2 className="felisSemiBold whiteColor" data-aos='fade-up'>Privacy Policy</h2>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Parallax>
          </Fragment> 
        )
      }
}


export default PricacyBanner