import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row } from 'bootstrap-4-react';
import LogoImg from '../../assets/SP_Home_Assets-02.svg';
import Image1 from '../../assets/ExteriorAssets/SUDARSAN PLANX INNER Asset-08.jpg';
import { Parallax } from "react-parallax";

class InteriorExterior extends PureComponent {

  render() {
    return (
      <Fragment>


        <section className="exterior-exterior-sec">

          <Container className="exterior-exterior-container">

            <Row className="exterior-exterior-row">
              <Col md={12}>
                <div className="exterior-white-head" data-aos='fade-left'>
                  <h4 className="felisBold">Interior</h4>
                </div>
                <div className="exterior-img" data-aos='fade-up'>
                  <img src={Image1} className="img-fluid d-md-none" />
                  <Parallax bgImage={Image1} strength={600} className="d-md-block">
                  </Parallax>
                </div>
                <div className="exterior-black-head" data-aos='fade-right'>
                  <h4 className="felisBold">Interior</h4>
                </div>
              </Col>
            </Row>

            <Row className="exterior-desc-row">
              <Col md={6} className="exterior-desc-col">
                <div className="exterior-desc-content" data-aos='fade-right'>
                  <p> Sudarsan Planx spruces up your interior spaces with our versatile and aesthetic planks. Our premium planks liven up your rooms, providing a rustic, woodsy aura being relatively maintenance-free when compared to real wood. Our planks, with their anti-slip surface feature, are an impressive substitute for wooden flooring. The planks, being resistant to corrosion, salinity and water, ensure that the wear and tear due to time is significantly reduced. </p>

                </div>
              </Col>
              <Col md={6} className="exterior-desc-col">
                <div className="exterior-desc-content" data-aos='fade-left'>
                  <p>Our planks, with their unique designs, can also be used as wall cladding, elevating the surrounding space and portraying an aura of richness and leisure. Our superior quality WPC planks come in two design patterns- grained and grooved, which can be used with three colour options- coffee, latte and chocolate and two different models - Planx Xl and Planx Hollow. </p>
                </div>
              </Col>
            </Row>

          </Container>
        </section>
      </Fragment>
    )
  }
}


export default InteriorExterior