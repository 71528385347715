import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row } from 'bootstrap-4-react';
import Image1 from '../../../assets/BlogInnerPage/SP_Blogs_assets-11.jpg';
import Image2 from '../../../assets/BlogInnerPage/SP_Blogs_assets-12.jpg';
 
import { Link } from "react-router-dom";

class LatestBlogs extends PureComponent {

    render() {
        return (
            <Fragment>
                <section className="latest-blogs-sec inner-latest-blog">  
                        <Container fluid className="latest-blogs-container"> 
                            <Row className="latest-blogs-row">
                                <Col md={6} className="latest-blogs-col"> 
                                    <div className="blogs-card"  data-aos='fade-right'>
                                        <a href="blog-inner">
                                        <div className="blogs-img">
                                            <img src={Image1} className="img-fluid" />
                                        </div>
                                        <div className="blogs-content">
                                            <h4>Lorem ipsum dolor sit amet .</h4>
                                            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
                commodo ligula eget dolor. Aenean massa.</p>
                                            <p className="blog-date">14 JUL ‘22</p>
                                        </div>
                                        </a>
                                    </div> 
                                </Col>
                                <Col md={6} className="latest-blogs-col">
                                    <div className="blogs-card"  data-aos='fade-left'>
                                        <a href="blog-inner">
                                        <div className="blogs-img">
                                            <img src={Image2} className="img-fluid" />
                                        </div>
                                        <div className="blogs-content">
                                            <h4>Lorem ipsum dolor sit amet .</h4>
                                            <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
                commodo ligula eget dolor. Aenean massa.</p>
                                            <p className="blog-date">14 JUL ‘22</p>
                                        </div>
                                        </a>
                                    </div> 
                                </Col> 
                            </Row>
                    </Container>

                </section>

            </Fragment>
        )
    }
}


export default LatestBlogs



