import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row } from 'bootstrap-4-react';

class WhychooseBanner extends PureComponent {

  render() {
    return (
      <Fragment>


        <section className="bannerSec blogInnerBanner blogbanner2">

          <Container fluid className="blog-banner-container">
            <Row className="common-flex-center">
              <Col md={8}>
                <h4 className='whiteColor' data-aos='fade-up'>Why Choose Sudarsan Planxs?
                </h4>
                <p className="blog-date whiteColor" data-aos='fade-up'>14 JUL ‘22</p>
              </Col>
            </Row>
          </Container>
        </section>
      </Fragment>
    )
  }
}


export default WhychooseBanner