import React, {  Fragment } from 'react'; 
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore,{ Navigation, Thumbs, Mousewheel, Pagination, Autoplay} from "swiper";
import { Container, Col, Row } from 'bootstrap-4-react'; 
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';  
import { useState } from "react";

import amitiesImage1 from './../../assets/new-imgs/application-page-1a.jpg';  

import amitiesImg1 from './../../assets/ApplicationAssets/SUDARSAN PLANX INNER Asset-02.jpg';
import amitiesImg2 from './../../assets/new-imgs/application-page-1b.jpg';
import amitiesImg3 from './../../assets/new-imgs/application-page-2b.jpg';
import amitiesImg4 from './../../assets/new-imgs/application-page-3b.jpg';
import amitiesImg5 from './../../assets/new-imgs/application-page-4b.jpg';

import amitiesImage2 from './../../assets/new-imgs/application-page-2a.jpg';  
import amitiesImage3 from './../../assets/new-imgs/application-page-3a.jpg';  
import amitiesImage4 from './../../assets/new-imgs/application-page-4a.jpg';  
import amitiesImage5 from './../../assets/new-imgs/application-page-5a.jpg';  
import amitiesImage6 from './../../assets/SP_Home_Assets-07.jpg';    
 
SwiperCore.use([ Autoplay ]);
export default function ApplicationGates() {
  const [imagesNavSlider, setImagesNavSlider] = useState(null);
  return (
    <Fragment>   
    <section className="applicationgates-sec slider" id="applicationgates-sec"> 
      <Container fluid className="amenities-swiper-sec wrap">
            <Row className="amenities-swiper-row">  
                <Col md={5}> 
                <div className="amenities-swiper-column" >
                 
                        <div className="amenities-swiper-col">  
                        
                            <div className="amenities-swiper">
                            <Swiper onSwiper={setImagesNavSlider}  direction="vertical" spaceBetween={24} slidesPerView={6}
                                 
                                className="swiper-container1"
                                breakpoints={{
                                0: {
                                    direction: "horizontal"
                                },
                                768: {
                                    direction: "horizontal",
                                    spaceBetween:0
                                },
                                1024: {
                                    direction: "horizontal",
                                    spaceBetween:0
                                },
                                1280: {
                                    direction: "vertical",
                                    spaceBetween:24
                                }
                                }}
                                modules={[Navigation, Thumbs]}
                            >
                                <SwiperSlide>
                                    <div className="applicationgates-text-div">
                                        
                                        <p className="felisBold">Railings and Louvers</p>
                                    </div>
                                   

                                </SwiperSlide> 
                                <SwiperSlide>
                                    <div className="applicationgates-text-div">
                                        <p className="felisBold">Claddings</p>
                                    </div> 
                                </SwiperSlide> 
                                <SwiperSlide>
                                    <div className="applicationgates-text-div">
                                        <p className="felisBold">Decking and Tiles</p>
                                    </div> 
                                </SwiperSlide> 
                                <SwiperSlide>
                                    <div className="applicationgates-text-div">
                                        <p className="felisBold">Pergolas and Fins</p>
                                        
                                    </div> 
                                </SwiperSlide> 
                                <SwiperSlide>
                                    <div className="applicationgates-text-div">
                                         <p className="felisBold">Gates</p>
                                    </div> 
                                </SwiperSlide>   
                            
                            </Swiper>
                            </div> 
                        
                        </div>
                        </div>
                </Col>
                <Col md={7}> 
               
                    <div className="amenities-images">
                        <Swiper 
                        thumbs={{ swiper: imagesNavSlider && !imagesNavSlider.destroyed ? imagesNavSlider : null }} 
                        direction="horizontal"
                        slidesPerView={1}
                        spaceBetween={0}
                        mousewheel={false}
                        autoplay={false} 
                        loop={true} 
                        speed= {1000} 
                        breakpoints={{
                          0: {
                            direction: "horizontal"
                          },
                          768: {
                            direction: "horizontal"
                          }
                        }}
                        className="swiper-container2"
                        modules={[Navigation, Thumbs, Mousewheel]}
                      >
                        <SwiperSlide>
                                {/* <div className="applicationgates-bg-img position-relative"> 
                                        <img src={amitiesImage1} className="img-fluid bigImage" alt="highlights-img7" />  
                                        <div className="applicationgates-small-img"> 
                                          <img src={amitiesImg1} className="img-fluid bottomImage4" alt="highlights-img7" />  
                                      </div>  
                                </div> */}
                                <div className="row">
                                      <div className="col-md-4 desc-col">
                                            <div className="desc">
                                                <p>Our planxs are the best choice for your railings and louvers as the beauty of natural wood is weaved into a durable composite. Thanks to their high resistance to deterioration and low maintenance, they can stay exposed to severe weather conditions for years and yet remain looking brand new. They do not warp or rot, and are splinter-proof and anti-corrosive making them the ideal choice to be used even by the seaside!</p>
                                            </div>
                                      </div>
                                      <div className="col-md-8">
            
                                        <div className="position-relative text-right">
                                            <img src={amitiesImage1} className="img-fluid mainImage"/> 
                                            <div className="bottomImage4 position-absolute">
                                                <img src={amitiesImg2} className="img-fluid textImage"  /> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              
                            </SwiperSlide> 
                            <SwiperSlide>
                                <div className="row">
                                      <div className="col-md-4 desc-col">
                                            <div className="desc">
                                                <p>Sudarsan Planx changes the entire façade of your building with its cladding boards. They maintain the look of your building for years as they are extremely resilient to fading. They provide an extra layer of insulation and ensure that there isn’t any water leakage. It’s highly versatile and merges well with both contemporary and traditional designs.</p>
                                            </div>
                                      </div>
                                      <div className="col-md-8">
            
                                        <div className="position-relative text-right">
                                            <img src={amitiesImage2} className="img-fluid mainImage"/> 
                                            <div className="bottomImage4 position-absolute">
                                                <img src={amitiesImg3} className="img-fluid textImage"  /> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                  
                            </SwiperSlide> 
                            <SwiperSlide>
                                <div className="row">
                                      <div className="col-md-4 desc-col">
                                            <div className="desc">
                                                <p>We provide planxs for decking and tiles in colour options that will complement the look of your space effortlessly. Our planxs are easy to maintain but are high in performance. Their anti-skid surface enables you to feel the texture beneath your feet when you go barefoot! And most importantly they are easy to install and last long with very low maintenance.</p>
                                            </div>
                                      </div>
                                      <div className="col-md-8">
            
                                        <div className="position-relative text-right">
                                            <img src={amitiesImage3} className="img-fluid mainImage"/> 
                                            <div className="bottomImage4 position-absolute">
                                                <img src={amitiesImg4} className="img-fluid textImage"  /> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide> 
                            <SwiperSlide>
                                <div className="row">
                                      <div className="col-md-4 desc-col">
                                            <div className="desc">
                                                <p>For pergolas and fins, gazebos and elevation, we recommend the Planx Hollow. They are much lighter than actual wood and steel frames and are comparatively much easier to install. They are weatherproof and termite resistant. They give you the same wood-like finish with much cheaper maintenance. Employ them in car parks, terraces, gardens and gazebos to enhance their natural aesthetics!</p>
                                            </div>
                                      </div>
                                      <div className="col-md-8">
            
                                        <div className="position-relative text-right">
                                            <img src={amitiesImage4} className="img-fluid mainImage"/> 
                                            <div className="bottomImage4 position-absolute">
                                                <img src={amitiesImg5} className="img-fluid textImage"  /> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide> 
                            <SwiperSlide>
                                <div className="row">
                                      <div className="col-md-4 desc-col">
                                            <div className="desc">
                                                <p>A gate is more than an entryway for a house; it is what welcomes people into your home! Sudarsan Planx’s ultra-light, premium-quality composite planxs are perfect for your gates as they come in colours and surface options that augment its look and feel. Our planxs meet the highest international standards and address bespoke decorative requirements, making them the obvious and easy choice for an alternative exterior option.</p>
                                            </div>
                                      </div>
                                      <div className="col-md-8">
            
                                        <div className="position-relative text-right">
                                            <img src={amitiesImage5} className="img-fluid mainImage"/> 
                                            <div className="bottomImage4 position-absolute">
                                                <img src={amitiesImg1} className="img-fluid textImage"  /> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>  
                        </Swiper>
                    </div> 
               
                </Col>
            </Row>
      </Container>
  
    </section>
    </Fragment>
  );
}
