import React, { PureComponent, Fragment } from 'react';

import Header from '../../../common/header';
import Footer from '../../../common/footer'; 

import LetsTalk from '../../../components/blog-component/lets-talk';
import BlogInnerBanner from '../../../components/blog-component/blog-inner/blog-inner-banner';
import BlogInnerMain from '../../../components/blog-component/blog-inner/blog-inner-main';
import LatestBlogs from '../../../components/blog-component/blog-inner/latest-blogs';

class BlogInnerPage extends PureComponent {
    render() {
        return (

            <Fragment>
 
                <Header />
                <BlogInnerBanner />

                <BlogInnerMain />

                <LatestBlogs />

                <LetsTalk />

                <Footer />

            </Fragment>
        )

    }
}

export default BlogInnerPage