import React, { PureComponent, Fragment } from 'react';

import Header from '../../common/header';
import Footer from '../../common/footer'; 
import InteriorBanner from '../interior-component/interior-banner';
import InteriorExterior from '../interior-component/interior-exterior';
import InteriorSlider1 from '../interior-component/interior-slider1';
// import InteriorSlider2 from '../interior-component/interior-slider2';
import InteriorOurProjects from '../interior-component/interior-our-projects';
import LetsTalk from '../interior-component/lets-talk';

class InteriorPage extends PureComponent {
    render() {
        return (

            <Fragment>
 
                <Header />
                <InteriorBanner />

                <InteriorExterior />


                 <InteriorSlider1 />

                 {/* <InteriorSlider2 /> */}

                 <InteriorOurProjects />

                 <LetsTalk />

                <Footer />

            </Fragment>
        )

    }
}

export default InteriorPage