import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row } from 'bootstrap-4-react';
import { Link } from 'react-router-dom'; 
import Image1 from '../../assets/SP_Home_Assets-06.jpg';
import Image2 from '../../assets/h.jpg';
import { Parallax, Background } from "react-parallax";
const container = {
    hidden: {
        opacity: 0,
    },
    show: {
        opacity: 1,

        transition: {
            delayChildren: 2,
            staggerChildren: 0.3,
        }
    }

}

const item = {
    hidden: {
        opacity: 0,
    },
    show: {
        opacity: 1,
    }

}


class HomeOurProducts extends PureComponent {

    render() {
        return (
            <Fragment>
                {/* <section className="our-products">

                    <Container fluid>

                        <Row>



                            <span className='lineclass1'></span>
                            <h1 className='felisRegular'>  Our Products</h1>
                            <span className='lineclass2'></span>
                            <h1 className='felisRegular'>  Our Products</h1>
                            <span className='lineclass3'></span>

                        </Row>

                        <Row>

                            <Col md={12} className='centerIMgDiv'>

                                <div>

                                <button className='ViewTheDesignButton'>View The Design</button>
                                    <img src={Image1} className='ourproductsleftimg' />

                                   
                                </div>
                                <div>
                                <button className='ViewTheDesignButton1'>View The Design</button>
                                <img src={Image2} className='ourproductsrightimg' />
                                </div>
                            </Col>


                        </Row>



                    </Container>

                </section> */}

                <section className="sp-our-products">
                    <div className="maqueee-container-head">
                        <div className="marquee-content marquee">
                            <p className='felisRegular'>Our Products</p>
                            <p className='felisRegular'>Our Products</p>
                            <p className='felisRegular'>Our Products</p>
                            <p className='felisRegular'>Our Products</p>
                            <p className='felisRegular'>Our Products</p>
                            <p className='felisRegular'>Our Products</p>
                            <p className='felisRegular'>Our Products</p>
                            <p className='felisRegular'>Our Products</p>
                        </div>
                        <div className="marquee-content marquee">
                            <p className='felisRegular'>Our Products</p>
                            <p className='felisRegular'>Our Products</p>
                            <p className='felisRegular'>Our Products</p>
                            <p className='felisRegular'>Our Products</p>
                            <p className='felisRegular'>Our Products</p>
                            <p className='felisRegular'>Our Products</p>
                            <p className='felisRegular'>Our Products</p>
                            <p className='felisRegular'>Our Products</p>
                        </div>

                    </div>

                    <Container fluid className="sp-our-products-conatiner">
                        <Row className="sp-our-products-row">
                            {/* <Col md={6} className="sp-our-products-col padding-zero" data-aos='fade-right'>
                                <div className="sp-our-products-div">
                                    <img src={Image1} className="img-fluid d-md-none" alt="prod-img" />
                                    <Parallax bgImage={Image1} strength={300} className="d-md-block">
                                    </Parallax>
                                    <h4 className="felisRegular">Interior</h4>
                                    <div className="lets-talk-button">
                                        <Link to="/interior" className="spButton">VIEW THE DESIGN</Link> 
                                        
                                    </div>
                                </div>
                            </Col> */}
                            <Col md={11} className="sp-our-products-col padding-zero" data-aos='fade-left'>
                                <div className="sp-our-products-div">
                                    <Parallax bgImage={Image2} strength={300} className="d-md-block">
                                    </Parallax>
                                    <img src={Image2} className="img-fluid d-md-none" alt="prod-img" />
                                    <h4 className="felisRegular">Exterior</h4>
                                    <div className="lets-talk-button">
                                        <Link to="/exterior" className="spButton"> VIEW THE DESIGN  </Link> 
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

            </Fragment>
        )
    }
}


export default HomeOurProducts

// import React, { PureComponent, Fragment } from 'react';
// import { Container, Col, Row } from 'bootstrap-4-react';
// import { Link } from 'react-router-dom'; 


// import Image1 from '../../assets/SP_Home_Assets-06.jpg';
// import Image2 from '../../assets/SP_Home_Assets-07.jpg';

// const container = {
//     hidden:{
//         opacity:0,
//     },
//     show: {
//         opacity:1,
  
//         transition:{
//             delayChildren:2,
//             staggerChildren:0.3,
//         }
//     }
  
//   }
  
//   const item = {
//     hidden:{
//         opacity:0,
//     },
//     show: {
//         opacity:1,
//     }
  
//   }


// class HomeOurProducts extends PureComponent {

//     render() {
//         return (
//             <Fragment>
              
//                 <section className="sp-our-products" variants={container} initial="hidden" animate="show">
//                     <div className="maqueee-container-head" >
//                         <div className="marquee-content marquee">
//                             <p className='felisRegular'>Our Products</p>
//                             <p className='felisRegular'>Our Products</p>
//                             <p className='felisRegular'>Our Products</p>
//                             <p className='felisRegular'>Our Products</p>
//                             <p className='felisRegular'>Our Products</p>
//                             <p className='felisRegular'>Our Products</p>
//                             <p className='felisRegular'>Our Products</p>
//                             <p className='felisRegular'>Our Products</p>
//                         </div>
//                         <div className="marquee-content marquee">
//                             <p className='felisRegular'>Our Products</p>
//                             <p className='felisRegular'>Our Products</p>
//                             <p className='felisRegular'>Our Products</p>
//                             <p className='felisRegular'>Our Products</p>
//                             <p className='felisRegular'>Our Products</p>
//                             <p className='felisRegular'>Our Products</p>
//                             <p className='felisRegular'>Our Products</p>
//                             <p className='felisRegular'>Our Products</p>
//                         </div>

//                     </div>

//                     <Container fluid className="sp-our-products-conatiner">
//                         <Row className="sp-our-products-row">
//                             <Col md={6} className="sp-our-products-col padding-zero">
//                                 <div className="sp-our-products-div">
//                                     <img src={Image1} className="img-fluid" alt="prod-img" />
//                                     <h4 className="felisRegular" data-scroll data-scroll-delay="0.13" data-scroll-speed='2'>Interior</h4>
//                                     <div className="lets-talk-button">
//                                         <Link to="/interior" className="spButton" data-scroll data-scroll-delay="0.13" data-scroll-speed='2'>VIEW THE DESIGN</Link> 
                                        
//                                     </div>
//                                 </div>
//                             </Col>
//                             <Col md={6} className="sp-our-products-col padding-zero">
//                                 <div className="sp-our-products-div">
//                                     <img src={Image2} className="img-fluid" alt="prod-img" />
//                                     <h4 className="felisRegular" data-scroll data-scroll-delay="0.13" data-scroll-speed='2'>Exterior</h4>
//                                     <div className="lets-talk-button">
//                                         <Link to="/exterior" className="spButton" data-scroll data-scroll-delay="0.13" data-scroll-speed='2'> VIEW THE DESIGN  </Link> 
//                                     </div>
//                                 </div>
//                             </Col>
//                         </Row>
//                     </Container>
//                 </section>

//             </Fragment>
//         )
//     }
// }


// export default HomeOurProducts