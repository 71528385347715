import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row } from 'bootstrap-4-react';
class AboutPlanxs extends PureComponent {

  render() {
    return (
      <Fragment>

        <section className="planxs-text-sec">
          <Container fluid>
            <Row>
              <Col md="12">
              
                <div className="track">
                  <div className="content">Planx's  </div> <div className="content">Planx's  </div> <div className="content">Planx's  </div> <div className="content">Planx's  </div> <div className="content">Planx's  </div> <div className="content">Planx's  </div> <div className="content">Planx's  </div> <div className="content">Planx's  </div> <div className="content">Planx's  </div> <div className="content">Planx's  </div> <div className="content">Planx's </div> <div className="content">Planx's  </div>
                </div>
            
              </Col>
            </Row>
          </Container>
        </section>


      </Fragment>
    )
  }
}


export default AboutPlanxs