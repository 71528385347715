import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row } from 'bootstrap-4-react';
import Image1 from '../../../assets/BlogInnerPage/SP_Blogs_assets-11.jpg';
import Image2 from '../../../assets/BlogInnerPage/SP_Blogs_assets-12.jpg';

import { Link } from "react-router-dom";

class WpcLatestBlogs extends PureComponent {

    render() {
        return (
            <Fragment>
                <section className="latest-blogs-sec inner-latest-blog">
                    <Container fluid className="latest-blogs-container">
                        <Row className="latest-blogs-row">
                            <Col md={6} className="latest-blogs-col">
                                <div className="blogs-card" data-aos='fade-right'>
                                    <Link to="/why-choose-sp">
                                        <div className="blogs-img">
                                            <img src={Image1} className="img-fluid" />
                                        </div>
                                        <div className="blogs-content">
                                            <h4>Why Choose Sudarsan Planxs?</h4>
                                            <p>The look and feel of Sudarsan Planx is unrivalled to any other type of planks in the market.</p>
                                            <p className="blog-date">14 JUL ‘22</p>
                                        </div>
                                    </Link>
                                </div>
                            </Col>
                            <Col md={6} className="latest-blogs-col">
                                <div className="blogs-card" data-aos='fade-left'>
                                    <Link to="/process-of-laying">
                                        <div className="blogs-img">
                                            <img src={Image2} className="img-fluid" />
                                        </div>
                                        <div className="blogs-content">
                                            <h4>Process Of Laying A Deck Using The Planxs.</h4>
                                            <p>WPC is the perfect choice for pool decking because it is waterproof. </p>
                                            <p className="blog-date">14 JUL ‘22</p>
                                        </div>
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                </section>

            </Fragment>
        )
    }
}


export default WpcLatestBlogs



