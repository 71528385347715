import React, { PureComponent, Fragment } from 'react';


import Footer from '../../common/footer'; 
import Header from './../../common/header';

import LetsTalk from '../../components/application-component/lets-talk';
import ApplicationBanner from '../../components/application-component/application-banner';
import ApplicationMain from '../../components/application-component/application-main';
import ApplicationGates from '../../components/application-component/application-gates';
import ApplicationProjects from '../../components/application-component/application-projects';
import {Helmet} from "react-helmet";

class ApplicationPage extends PureComponent {
    render() {
        return (

            <Fragment> 

                <Helmet> 
                    <title>Sudarsan WPC Planx Applications - Best WPC wall cladding, decking tiles. </title>
                    <meta name="description" content="At Sudarsan Planx we have the ability to curate and employ WPC planks for a wide range of applications. Since they are less vulnerable to abrasions and are highly durable, our planks can be used for gates, fencing, duct covering, elevation designing, wall/ceiling panelling, gazebos and more!"></meta>
                    <link rel="canonical" href="https://www.sudarsanplanx.com/application" />
                </Helmet> 


                <Header />

                <ApplicationBanner />

                <ApplicationMain />

                <ApplicationGates />

                <ApplicationProjects />

                <LetsTalk />

                <Footer />

            </Fragment>
        )

    }
}

export default ApplicationPage