import React, { PureComponent, Fragment } from 'react';


import Footer from './../../common/footer';
import Header from './../../common/header';
import AboutBanner from './../about-component/about-banner';
import AboutTestimonials from './../about-component/about-testimonials';
import LetsTalk from './../about-component/lets-talk';
import AboutOurStory from './../about-component/about-our-story';
import AboutWeBelieve from './../about-component/about-we-believe';
import AboutHowWeWork from './../about-component/about-how-we-work';
import AboutPlanxs from './../about-component/about-planxs';
import AboutWhatMakes from './../about-component/about-what-makes';
import {Helmet} from "react-helmet";



class AboutPage extends PureComponent {
    render() {
        return (

            <Fragment>

                <Helmet> 
                    <title>Best WPC railings and louvers, cladding, decking tiles, pergola & fins, gates </title>
                    <meta name="description" content="Our planxs are engineered to flatter the design and development of various applications by evaluating its utility, functionality and aesthetics."></meta>
                    <link rel="canonical" href="https://www.sudarsanplanx.com/about" />
                </Helmet> 

                <Header />

                <AboutBanner />

                <AboutOurStory />

                <AboutWeBelieve />

                <AboutHowWeWork />

                <AboutPlanxs />

                <AboutWhatMakes />

                <AboutTestimonials />

                <LetsTalk />



                <Footer />

            </Fragment>
        )

    }
}

export default AboutPage