import React, { PureComponent, Fragment } from 'react';

import HomeBanner from './home-banner';
import HomeOurStory from './home-our-story';
import HomeOurProducts from './home-our-products';

import UniqueFeatures from './unique-features';
import LatestInsights from './latest-insights';
import HomeTestimonials from './home-testimonials';
import LetsTalk from './lets-talk';
import Footer from './../../common/footer';
import Header from './../../common/header';


import HomeApplications from './home-applications';
import {Helmet} from "react-helmet";
class HomePage extends PureComponent {
  render() {
    return (

      <Fragment>
            <Helmet> 
                <title>India’s Best WPC Planks for Floor decking, Wall cladding, Gates - Sudarsan Planx,Chennai</title>
                <meta name="description" content="Sudarsan Planx bridges personal style with environment-friendly planks to offer the best of both worlds! It is UV protected, weather-resistant, termite treated and anti-corrosive."></meta>
                <link rel="canonical" href="https://www.sudarsanplanx.com/home/" />
            </Helmet>
        <Header />

        <HomeBanner />

        <HomeOurStory />

        <HomeOurProducts />

        <UniqueFeatures />

        <HomeApplications />

        <LatestInsights />

        <HomeTestimonials />

        <LetsTalk />



        <Footer />

      </Fragment>
    )

  }
}

export default HomePage