import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row } from 'bootstrap-4-react';
import Image1 from '../../../assets/BlogInnerPage/SP_Blogs_assets-09.jpg';
import Image2 from '../../../assets/BlogInnerPage/SP_Blogs_assets-10.jpg'; 
import blogImage4 from '../../../assets/BlogInnerPage/SP_Blogs_assets-11.jpg';
import blogImage6 from '../../../assets/BlogInnerPage/SP_Blogs_assets-12.jpg';
import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";
class BlogInnerMain extends PureComponent {
 
    render() {
        return (
            <Fragment> 
                <section className="blog-inner-description-sec">
                    <Container fluid className="blog-inner-description-container">
                        <Row className="blog-inner-description-row">
                            <Col md={8}>
                                <div className="blog-inner-desc-text">
                                    <p  data-aos='fade-right'>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo
    ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis
    dis parturient montes, nascetur ridiculus mus.</p>
                                </div>
                              
                            </Col>
                        </Row>
                        <Row className="blog-inner-description-row">
                            <Col md={12}>
                                <div className="blog-inner-desc-img">
                                    <div className="blogs-img"  data-aos='fade-left'>
                                        <img src={Image1} className="img-fluid" alt="blog-inner-img" />
                                    </div>
                                </div>
                              
                            </Col>
                        </Row>
                        <Row className="blog-inner-description-row">
                            <Col md={8}>
                                <div className="blog-inner-desc-text">
                                    <p data-aos='fade-right'>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo
ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis
dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies
nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim.
Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim
justo, rhoncus ut, imperdiet a, venenatis vitae, justo. Nullam dictum felis eu
pede mollis pretium. Integer tincidunt. Cras dapibus.</p>
                                </div>
                              
                            </Col>
                        </Row>
                        <Row className="blog-inner-description-row">
                            <Col md={12}>
                                <div className="blog-inner-desc-img">
                                    <div className="blogs-img"  data-aos='fade-left'>
                                        <img src={Image2} className="img-fluid" alt="blog-inner-imgs" />
                                    </div>
                                </div>
                              
                            </Col>
                        </Row>
                        <Row className="blog-inner-description-row">
                            <Col md={8}>
                                <div className="blog-inner-desc-border-bottoom">
                                   
                                </div>
                              
                            </Col>
                        </Row>
                        <Row className="blog-inner-description-row">
                            <Col md={8}>
                                <div className="blog-inner-social-share">
                                    <div class="flex-social-div">
                                        <p>Share</p>
                                        <a href="#"><FaFacebookF /></a>
                                        <a href="#"><FaTwitter /></a>
                                        <a href="#"><FaLinkedinIn /></a>
                                    </div> 
                                </div> 
                            </Col>
                        </Row>
 
                    </Container>

                    
                </section>
            </Fragment>
        )
    }
}


export default BlogInnerMain



