import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row } from 'bootstrap-4-react';
// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

class HomeTestimonials extends PureComponent {

    render() {
        return (
            <Fragment>
                {/* <section className="home-testimonials">

                    <Container fluid className="home-testimonials-container">
                        <Row>
                            <h1 className='AktivGroteskBold'>Testimonials</h1>
                        </Row>

                        <div>

                            <Swiper className='swiperaligncenter' 
                                modules={[Navigation, Pagination, Scrollbar, A11y]}
                                spaceBetween={0}
                                slidesPerView={1}
                                centeredSlides
                                navigation 

                                breakpoints={{
                                    320: { 
                                      slidesPerView: 1,
                                    }, 
                                  }} 
                            >
                                <SwiperSlide className='SwiperSlide'>
                                    <div className='SwiperSlide2'>
                                        <h1 className='AktivGroteskBold'>Parthiban Raj</h1>
                                        <p className='AktivGroteskBold'>Useful and durable fit for the house. Perfect for
                                            new house owners who are looking to add
                                            something nice to their decor. I bought Planx XL
                                            for my front porch and the exterior looks great.
                                            Very satisfied with the outcome.</p>

                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className='SwiperSlide'>

                                    <div className='SwiperSlide2'>
                                        <h1 className='AktivGroteskBold'>Parthiban Raj</h1>
                                        <p className='AktivGroteskBold'>Useful and durable fit for the house. Perfect for
                                            new house owners who are looking to add
                                            something nice to their decor. I bought Planx XL
                                            for my front porch and the exterior looks great.
                                            Very satisfied with the outcome.</p>

                                    </div>


                                </SwiperSlide>
                                <SwiperSlide className='SwiperSlide'>

                                    <div className='SwiperSlide2'>
                                        <h1 className='AktivGroteskBold'>Parthiban Raj</h1>
                                        <p className='AktivGroteskBold'>Useful and durable fit for the house. Perfect for
                                            new house owners who are looking to add
                                            something nice to their decor. I bought Planx XL
                                            for my front porch and the exterior looks great.
                                            Very satisfied with the outcome.</p>

                                    </div>


                                </SwiperSlide>
                                <SwiperSlide className='SwiperSlide'>

                                    <div className='SwiperSlide2'>
                                        <h1 className='AktivGroteskBold'>Parthiban Raj</h1>
                                        <p className='AktivGroteskBold'>Useful and durable fit for the house. Perfect for
                                            new house owners who are looking to add
                                            something nice to their decor. I bought Planx XL
                                            for my front porch and the exterior looks great.
                                            Very satisfied with the outcome.</p>

                                    </div>

                                </SwiperSlide>

                            </Swiper>
                        </div>

                    </Container>

                </section> */}


            <section className="sp-home-testimonials">
                <Container fluid  data-aos='fade-up'>
                    <Row className="common-flex-center">
                        <Col md={8}>
                            <div className="common-heading">
                                <h1 className='felisBold'> Testimonials </h1> 
                                <div className='lineclassx'></div>
                            </div>
                        </Col>
                    </Row> 
                </Container>

            <Container fluid className="sp-testimonials-container"  data-aos='fade-up'> 
                <Row>
                    <Col md={12}>
                        <div className="testimonials-swiper-div">
                            <Swiper className='testimonials-swiper' 
                                modules={[Navigation, Pagination, Scrollbar, A11y]}
                                spaceBetween={10}
                                slidesPerView={1}
                                centeredSlides={true}
                                navigation={true}
                                pagination={{ clickable: true }}
                                loop={true} >
                                <SwiperSlide className='testimonials-swiper-slide'>
                                    <div className="swiper-slide-div">
                                        <h1 className='felisRegular'>Parthiban Raj</h1>
                                        <p>Useful and durable fit for the house. Perfect for new house owners who are looking to add something nice to their decor. I bought Planx XL for my front porch and the exterior looks great. Very satisfied with the outcome.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className='testimonials-swiper-slide'>
                                    <div className="swiper-slide-div">
                                        <h1 className='felisRegular'>Arun Eashwar</h1>
                                        <p>These Planx are the best and cost-effective alternative for timber planks. The decking of these planks were even and anti-skid. Perfect for a surrounding with children. Their colour options are perfect and they have a great finish, too. Very easy to install as well. Got a lot of compliments for my home exterior. Highly satisfied with my purchase.</p>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide className='testimonials-swiper-slide'>
                                    <div className="swiper-slide-div">
                                        <h1 className='felisRegular'>Prakash Kumar</h1>
                                        <p>The planks I purchased from Sudarsan Planx were very easy to fit and long-lasting. I purchased the Hollow Planx and it gives my home exterior a very aesthetic look, just as I wanted. Extremely happy with the product.</p>
                                    </div>
                                </SwiperSlide>
                            
                                
                            </Swiper>
                        </div>

                    </Col>
                </Row>

       
            </Container>

            </section>

            </Fragment>
        )
    }
}


export default HomeTestimonials