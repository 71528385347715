import React, { PureComponent, Fragment } from 'react';
import { Container, Col, Row } from 'bootstrap-4-react'; 

class ProcessBanner extends PureComponent {

  render() {
    return (
      <Fragment>


        <section className="bannerSec blogInnerBanner blogbanner3">

          <Container fluid className="blog-banner-container"> 
              <Row className="common-flex-center">
                  <Col md={8}>
                        <h4 className='whiteColor' data-aos='fade-up'>Process Of Laying A Deck Using The Planxs  </h4>
                        <p className="blog-date whiteColor"  data-aos='fade-up'>14 JUL ‘22</p>
                  </Col>
              </Row>
          </Container>
        </section>
      </Fragment>
    )
  }
}


export default ProcessBanner