// import React, { PureComponent, Fragment } from 'react';

// import { Link, NavLink } from 'react-router-dom'

// import { Container, Col, Row, Button } from 'bootstrap-4-react';

// import { Navbar, Nav } from 'react-bootstrap';


// class Header extends PureComponent {


//     openNav() {
//         document.getElementById("myNav").style.display = "block";

//     }

//     closeNav() {
//         document.getElementById("myNav").style.display = "none";
//     }

//     render() {
//         return (

//             <Fragment>

//                 <Container fluid className="headerDiv">

//                     <span onClick={this.openNav} className="hammenu">&#9776;</span>


//                     <div id="myNav" className="overlay">
//                         <span  className="closebtn" onClick={this.closeNav}>&times;</span>
//                         <div className="overlay-content">
//                             <Navbar>

//                                 <Nav className="flex-column">
//                                     <Nav.Item>
//                                         <Nav.Link as={NavLink} to='/' ><span>Home</span></Nav.Link>
//                                     </Nav.Item>
//                                     <Nav.Item>
//                                         <Nav.Link as={NavLink} to='/about'><span>About</span></Nav.Link>
//                                     </Nav.Item>
//                                     <Nav.Item>
//                                         <Nav.Link as={NavLink} to='/exterior'><span>Products</span></Nav.Link>
//                                     </Nav.Item>
//                                     <Nav.Item>
//                                         <Nav.Link as={NavLink} to='/application'><span>Applications</span></Nav.Link>
//                                     </Nav.Item>
//                                     <Nav.Item>
//                                         <Nav.Link as={NavLink} to='/blog'><span>Blogs</span></Nav.Link>
//                                     </Nav.Item>
//                                     <Nav.Item>
//                                         <Nav.Link as={NavLink} to='/contact'><span>Contact</span></Nav.Link>
//                                     </Nav.Item>
//                                 </Nav>
//                             </Navbar>
//                         </div>
//                     </div>
//                 </Container>
//             </Fragment>

//         )


//     }

// }


// export default Header;

import React, { PureComponent, Fragment } from 'react';
import { Link } from 'react-router-dom';
// import './../../styles/header.css';
import Logo from './../assets/SP_Home_Assets-03.svg';
import LogoImg from '../assets/SP_Home_Assets-02.svg';
import { Navbar, Container, Row, Col, Nav, NavDropdown, Form } from 'react-bootstrap';

class Header extends PureComponent {

  constructor(props) {
    super(props);
    this.state = { addClass: false, addClass1: false, addClass2: false }
  }
  toggle() {
    this.setState({ addClass: !this.state.addClass });
  }
  toggle1() {
    this.setState({ addClass1: !this.state.addClass1 });
  }
  toggle2() {
    this.setState({ addClass2: !this.state.addClass2 });
  }

  render() {
    let menuClass = ["main-menu-button main-menu-button--with-lang loaded"];
    let navBar = ["navbar hide-menu show-mobile-bg"];
    let openMenu = ["main-menu hide loaded"];
    let submenuClass = ["child sibling submenu-item"]
    let submenuClass1 = ["child sibling submenu-item"]
    if (this.state.addClass) {
      menuClass.push('show-menu');
      navBar.push('show-menu');
      openMenu.push('show');
    }
    if (this.state.addClass1) {
      submenuClass.push('active');
      menuClass.push('show-menu');
      navBar.push('show-menu');
      openMenu.push('show');
    }
    if (this.state.addClass2) {
      submenuClass1.push('active');
      menuClass.push('show-menu');
      navBar.push('show-menu');
      openMenu.push('show');
    }
    //   Founder/ Fitness of fithterorem chennai -Modal popup full story details


    return (
      <Fragment>
        <Navbar fixed="top" bg="light" expand={false} className="logoNav">
          <Container fluid>
            <Navbar.Brand href="home">
              <img src={LogoImg} className="img-fluid" alt="Logo" />
            </Navbar.Brand>
          </Container>
        </Navbar>
        <div className="navigation sp-header">
          <div id="navbar" className={navBar.join(' ')} onClick={this.toggle.bind(this)}>
            <Link to="/" className="site-logo loaded">
              <img src={Logo} className="img-fluid" alt="Logo" />
            </Link>
            <div to="/" className={menuClass.join(' ')} onClick={this.toggle.bind(this)}>
              {/* <span className="title felisSemiBold">
                    <span className="open">Menu</span> 
                    <span className="close">Close</span>
                  </span>  */}
              <span className="bars">
                <span className="middle"></span>
              </span>
            </div>
            <div className="navbar-social-links">
              <p>Scroll</p>
              <div className="scroll-line"></div>
            </div>
          </div>

          <div id="main-menu" className={openMenu.join(' ')} onClick={this.toggle.bind(this)}>
            <div className="wrapper">
              <div className="main-menu-content">

                <nav>
                  <ul className="main-menu-list">
                    <div className="row">
                      <div className="col-md-6 dFlexDiv">
                        <li className="child selected">
                          <Link to="/home" className=""><span className="title felisSemiBold">Home</span></Link>
                        </li>
                        <li className="child sibling">
                          <Link to="/about" className=""><span className="title felisSemiBold">About</span></Link>
                        </li>
                        <li className="child sibling">
                          <Link to="/exterior" className=""><span className="title felisSemiBold">Products</span></Link>
                        </li>
                      </div>
                      <div className="col-md-6 dFlexDiv">
                        <li className="child sibling">
                          <Link to="/application" className=""><span className="title felisSemiBold">Applications</span></Link>
                        </li>
                        <li className="child sibling">
                          <Link to="/blog" className=""><span className="title felisSemiBold">Blogs</span></Link>
                        </li>
                        <li className="child sibling">
                          <Link to="/contact" className=""><span className="title felisSemiBold">Contact Us</span></Link>
                        </li>
                      </div>
                    </div>



                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>

      </Fragment>
    )
  }
}

export default Header;